import {
  MOIcon,
  MOInput,
  MOTooltip,
} from "@metsooutotec/modes-web-components/dist/react";
import React from "react";

import styles from "./style.module.scss";

type InputProps = React.ComponentProps<typeof MOInput> & {
  hint?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ hint, ...props }, ref) => {
    return (
      <div className={styles.inputBox}>
        {hint && (
          <MOTooltip
            placement="left"
            content={hint}
            hoist
            onMoAfterHide={(e) => e.stopPropagation()}
          >
            <MOIcon className={styles.tooltip} name="info" />
          </MOTooltip>
        )}

        {/* TODO: Fix this TS problem with ref */}
        {/* @ts-ignore */}
        <MOInput ref={ref} {...props} />
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
