import MOButton from "@metsooutotec/modes-web-components/dist/react/button";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Organization } from "@/api/organizationSlice/types";
import { useDeleteOrganization } from "@/queries/organizationsQuery/organizationsQuery";
import { QUERIES_KEYS } from "@/queries/queriesKeys";
import useNotificationStore from "@/store/useNotificationStore.store";
import { STATUS_TYPES } from "@/utils/consts";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { STATUS_KEYS } from "@/utils/utils";

import { useDialog } from "../../Dialog/DialogProvider";
import styles from "./deleteorganization.module.scss";

type DeleteOrganizationForm = {
  organizations: Organization[];
};

const DeleteOrganization = ({ organizations = [] }: DeleteOrganizationForm) => {
  const queryClient = useQueryClient();
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { mutate, isSuccess } = useDeleteOrganization();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  const { handleSubmit } = useForm<DeleteOrganizationForm>({
    defaultValues: {
      organizations: organizations,
    },
  });

  const handleDeleteOrganization = () => {
    organizations.forEach((organization) => {
      if (organization.status !== Number(STATUS_KEYS[STATUS_TYPES[2]])) {
        queryClient.invalidateQueries({
          queryKey: [QUERIES_KEYS.resources],
        });
        openNotification(
          t(LangKeys.NOTIFICATION_CREATE_NEW_RESOURCE_IN_PROGRESS),
          "info"
        );
        return;
      }

      mutate({ id: organization.id });
    });
  };

  if (isSuccess) {
    hideDialog();
  }

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.DELETE_ORGANIZATION)}</h2>

      <p>{t(LangKeys.ORGANIZATION_DELETE_CONFIRMATION_TEXT)}</p>

      <div className={styles.buttonsBox}>
        <MOButton
          variant="subtle"
          className={styles.buttons}
          onClick={hideDialog}
        >
          {t(LangKeys.CANCEL)}
        </MOButton>

        <MOButton
          className={styles.buttons}
          type="submit"
          onClick={handleSubmit(handleDeleteOrganization)}
        >
          {t(LangKeys.CONFIRM)}
        </MOButton>
      </div>
    </div>
  );
};

export default DeleteOrganization;
