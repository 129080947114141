import MOTab from "@metsooutotec/modes-web-components/dist/react/tab";
import MOTabGroup from "@metsooutotec/modes-web-components/dist/react/tab-group";
import MOTabPanel from "@metsooutotec/modes-web-components/dist/react/tab-panel";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LangKeys } from "@/utils/i18n/languageKeys";

import Icon from "../Icon";
import Input from "../Input";
import styles from "./styles.module.scss";
import {
  addNewVariableToExpressionInput,
  checkValuesInObject,
  MATH_CONSTANTS_LIST,
  MATH_FUNCTIONS_LIST,
  OperatorsList,
  replaceVariableValueToKeyInExpressionByExpressionInput,
} from "./utils";

type MathOperationInputProps = {
  variables?: { value: string; name: string }[];
  expressionData?: string;
  expressionInputData?: { [key: string]: string };
};

const MathOperationInput = ({
  variables,
  expressionData,
  expressionInputData,
}: MathOperationInputProps) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const [searchVariable, setSearchVariable] = useState("");
  const [expression, setExpression] = useState(
    replaceVariableValueToKeyInExpressionByExpressionInput(
      expressionData ?? "",
      expressionInputData ?? {}
    )
  );
  const [expressionInput, setExpressionInput] = useState<{
    [key: string]: string;
  }>(expressionInputData ?? {});
  const inputRef = useRef(null);
  const operators = OperatorsList();
  const { t } = useTranslation();

  const checkExpressionValue = (value: string) => {
    if (value.length === 0) {
      setExpression("");
      setExpressionInput({});
    }
    checkValuesInObject(expressionInput, value);
  };

  const handleAddToExpression = (value: string) => {
    insertAtCursor(value);
  };

  /**
   * Inserts the given text at the current cursor position within a textarea input.
   *
   * This function retrieves the current cursor position in the textarea, splits the
   * existing text at the cursor position, and inserts the new text at that position.
   * It then updates the state with the new text and sets the cursor position to the
   * end of the inserted text.
   *
   * @param text - The text to be inserted at the cursor position.
   */
  const insertAtCursor = (text: string) => {
    const input = inputRef.current as unknown as HTMLTextAreaElement;
    if (input) {
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const before = input.value.substring(0, start);
      const after = input.value.substring(end, input.value.length);
      const newText = before + text + after;

      setExpression(newText);

      setTimeout(() => {
        input.selectionStart = input.selectionEnd = start + text.length;
        input.focus();
      }, 0);
    }
  };

  useEffect(() => {
    setValue("expression", expression);
    setValue("expressionInput", expressionInput);
  }, [expression, expressionInput]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVariable(e.target.value);
  };

  const filteredOptions =
    variables?.filter((option) =>
      option.name.toLowerCase().includes(searchVariable.toLowerCase())
    ) ?? [];

  const variableList = (
    <div className={cn(styles.list, styles.variableList)}>
      {filteredOptions.map((item, index) => (
        <p
          key={`${item.name}-${index}`}
          onClick={() => {
            const newExpressionInput = addNewVariableToExpressionInput(
              expressionInput,
              item.value
            );
            setExpressionInput(newExpressionInput);
            handleAddToExpression(item.value);
          }}
        >
          {item.name}
        </p>
      ))}
    </div>
  );

  const operations = (
    <div className={cn(styles.list, styles.maxListSize)}>
      {operators.map((item) => (
        <p
          key={item.label}
          onClick={() => handleAddToExpression(` ${item.value} `)}
        >
          {item.label}
        </p>
      ))}
    </div>
  );

  const functions = (
    <div className={cn(styles.list, styles.maxListSize)}>
      {MATH_CONSTANTS_LIST.map((item) => (
        <p
          className={cn(styles.listElement)}
          key={item.label}
          onClick={() => handleAddToExpression(`${item.format}`)}
        >
          {item.label}
        </p>
      ))}

      {MATH_FUNCTIONS_LIST.map((item) => (
        <p
          className={cn(styles.listElement)}
          key={item.label}
          onClick={() => handleAddToExpression(`${item.format}`)}
        >
          {item.label}
        </p>
      ))}
    </div>
  );

  return (
    <div>
      <textarea
        className={cn(styles.textarea, {
          [styles.errorTextArea]: errors.expression,
        })}
        ref={inputRef}
        value={expression}
        rows={5}
        onChange={(e: any) => {
          setExpression(e.target.value);
          checkExpressionValue(e.target.value);
        }}
      />

      {errors.expression && (
        <p className={styles.errorTextArea}>
          {typeof errors?.expression?.message === "string"
            ? errors.expression.message
            : ""}
        </p>
      )}

      <MOTabGroup className={styles.tabGroup}>
        <MOTab slot="nav" panel="variable">
          {t(LangKeys.VARIABLES)}
        </MOTab>

        <MOTab slot="nav" panel="operations">
          {t(LangKeys.OPERATORS)}
        </MOTab>

        <MOTab slot="nav" panel="functions">
          {t(LangKeys.FUNCTIONS)}
        </MOTab>

        <MOTabPanel name="variable" className={styles.tabBody}>
          <Input
            label="Variable name"
            type="search"
            placeholder={t(LangKeys.SEARCH_VARIABLE)}
            onMoInput={(e: Event) => handleSearchChange(e as any)}
          >
            <Icon name="search" slot="suffix"></Icon>
          </Input>

          {variableList}
        </MOTabPanel>

        <MOTabPanel name="operations" className={styles.tabBody}>
          {operations}
        </MOTabPanel>

        <MOTabPanel name="functions" className={styles.tabBody}>
          {functions}
        </MOTabPanel>
      </MOTabGroup>
    </div>
  );
};

export default MathOperationInput;
