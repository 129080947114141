import { ColDef, RowClickedEvent } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import { Project } from "@/api/projectSlice/types";
import { Icon, TableButton, UpdateUser } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import DeleteUser from "@/components/Forms/DeleteUser";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

type UsersColumnsProps = {
  organizationId?: string;
  projects?: Project[];
  orgData?: {
    projects: Project[];
    users: any[];
  };
};

const useUsersColumns = ({ organizationId, projects }: UsersColumnsProps) => {
  const { t } = useTranslation();
  const { showDialog } = useDialog();

  const editDeleteButtons = (api: RowClickedEvent) => {
    return (
      <div className={styles.tableButtonGroup}>
        <TableButton
          onClick={(e) => {
            e.stopPropagation();
            showDialog(
              <UpdateUser
                userData={api.data}
                organizationId={organizationId ?? ""}
                organizationProjects={projects ?? []}
              />,
              "medium"
            );
          }}
        >
          <Icon name="edit" className={styles.icon} />
        </TableButton>

        <TableButton
          onClick={(e) => {
            e.stopPropagation();
            showDialog(
              <DeleteUser
                userData={api.data}
                organizationId={organizationId ?? ""}
              />,
              "medium"
            );
          }}
        >
          <Icon name="trash" className={styles.icon} />
        </TableButton>
      </div>
    );
  };

  const columns: ColDef[] = [
    {
      headerName: "ID",
      cellDataType: "string",
      field: "id",
      hide: true,
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.FULL_NAME),
      cellDataType: "string",
      field: "fullName",
    },
    {
      headerName: t(LangKeys.EMAIL_ADDRESS),
      cellDataType: "string",
      flex: 1,
      field: "emailAddress",
    },
    {
      headerName: "Edit",
      headerComponent: () => (
        <div className={styles.tableButtonGroup}>
          <TableButton>
            <Icon name="edit" className={styles.icon} />
          </TableButton>

          <TableButton>
            <Icon name="trash" className={styles.icon} />
          </TableButton>
        </div>
      ),
      field: "ediColumn",
      maxWidth: 120,
      cellRenderer: editDeleteButtons,
      cellClass: "cell-style",
    },
  ];

  return columns;
};

export default useUsersColumns;
