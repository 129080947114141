import { create } from "zustand";

type VariantType =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "alert";

type NotificationState = {
  isOpen: boolean;
  message: string;
  variant: VariantType;
  openNotification: (msg: string, variant?: VariantType) => void;
  closeNotification: () => void;
};

const useNotificationStore = create<NotificationState>((set) => ({
  isOpen: false,
  message: "",
  variant: "primary",
  openNotification: (msg, variant) =>
    set({ isOpen: true, message: msg, variant: variant }),
  closeNotification: () => set({ isOpen: false, message: "" }),
}));

export default useNotificationStore;
