import { RESOURCE_PLANT_TYPE } from "@/utils/consts";

type PlantTypeProps = {
  plantType: keyof typeof RESOURCE_PLANT_TYPE;
};

const PlantType = ({ plantType }: PlantTypeProps) => {
  return <p>{RESOURCE_PLANT_TYPE[plantType]}</p>;
};

export default PlantType;
