import cn from "classnames";

import Button from "../Button";
import Icon from "../Icon";
import styles from "./style.module.scss";

type BackButtonProps = {
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
};

/**
 * Simple styled navigate back button
 * @param onClick callback
 * @returns BackButton component
 */
const BackButton = ({ onClick, className, children }: BackButtonProps) => {
  return (
    <>
      <Button
        className={cn("navBackButton", styles.navBackButton, className)}
        variant="subtle"
        size="small"
        onClick={onClick}
      >
        <Icon slot="prefix" name="arrow-left"></Icon>
        {children}
      </Button>
    </>
  );
};

export default BackButton;
