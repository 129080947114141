import { useQuery } from "@tanstack/react-query";

import { OrganizationData } from "@/api/organizationSlice/types";
import { fetchProjects } from "@/api/projectSlice/projectSlice";

import { QUERIES_KEYS } from "../queriesKeys";

export const useProjects = (organizationId: string) => {
  return useQuery<OrganizationData>({
    queryKey: [QUERIES_KEYS.organizations, QUERIES_KEYS.users, organizationId],
    queryFn: () => fetchProjects({ organizationId }),
  });
};
