import { vestResolver } from "@hookform/resolvers/vest";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { Project, Role } from "@/api/projectSlice/types";
import { UpdateUserProjectSelection } from "@/api/userSlice/types";
import Button from "@/components/Button";
import { inputIsNotEmpty } from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./updateUser.module.scss";

const schema = create((data: UpdateUserProjectSelection) => {
  inputIsNotEmpty("projectId", data.projectId);
});

type UpdateUserProjectSelectProps = {
  index: number;
  projects?: Project[];
  userProjects: Project[];
  selectedProject?: Project;
  userRolesForSelectedProject?: Role[] | undefined;
  onSelectProjectAndRoles: (
    index: number,
    projectId: string,
    projectName: string,
    roles: Role[] | undefined
  ) => void;
  onRemoveProject: (index: number) => void;
};

const UpdateUserProjectSelect = ({
  index,
  projects,
  userProjects,
  selectedProject,
  userRolesForSelectedProject,
  onSelectProjectAndRoles,
  onRemoveProject,
}: UpdateUserProjectSelectProps) => {
  const { t } = useTranslation();

  const { register } = useForm<UpdateUserProjectSelection>({
    defaultValues: {
      projectId: "",
      roles: [],
    },
    resolver: vestResolver(schema),
  });

  return (
    <>
      <MOSelect
        clearable
        placeholder={t(LangKeys.SELECT_PROJECT)}
        label={t(LangKeys.PROJECT)}
        onMoChange={(event: Event) => {
          const target = event.target as HTMLInputElement;
          const id = target.value as string;

          onSelectProjectAndRoles(
            index,
            projects?.find((x) => x.id === id)?.name ?? "",
            id,
            undefined
          );
        }}
        value={selectedProject?.id ?? ""}
        onMoAfterHide={(e: any) => e.stopPropagation()}
        {...register("projectId")}
      >
        {projects?.map((projects) => {
          return (
            <MOOption
              key={projects.id}
              value={projects.id}
              disabled={userProjects.some(
                (userProject) => userProject.id === projects.id
              )}
            >
              {projects.name}
            </MOOption>
          );
        })}
      </MOSelect>

      <MOSelect
        multiple
        clearable
        disabled={
          selectedProject === undefined || selectedProject?.id.length === 0
        }
        placeholder={t(LangKeys.SELECT_ROLES)}
        label={t(LangKeys.ROLES)}
        value={userRolesForSelectedProject?.map((x) => x.id)}
        onMoChange={(e: any) => {
          const roleIds = e.target.value;
          const project = projects?.find((x) => x.id === selectedProject?.id);
          const projectName = project?.name;
          const roles = project?.roles.filter((r) => roleIds.includes(r.id));
          onSelectProjectAndRoles(
            index,
            projectName ?? "",
            project?.id ?? "",
            roles
          );
        }}
        onMoAfterHide={(e: any) => {
          e.stopPropagation();
        }}
        {...register("roles")}
      >
        {projects?.map((project) => {
          if (project.id === selectedProject?.id) {
            return project.roles.map((role) => {
              return (
                <MOOption key={role.id} value={role.id}>
                  {role.displayName}
                </MOOption>
              );
            });
          }
        })}
      </MOSelect>
      <Button
        type="button"
        variant="subtle"
        className={styles.removeButton}
        onClick={() => onRemoveProject(index)}
      >
        {t(LangKeys.REMOVE_PROJECT)}
      </Button>
    </>
  );
};

export default UpdateUserProjectSelect;
