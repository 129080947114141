import { createBrowserRouter, Navigate } from "react-router-dom";

import { RootElement } from "@/components";
import {
  OrganizationsPage,
  ResourcesPage,
  UsersPage,
  VariablesPage,
} from "@/pages";

const MainRouter = createBrowserRouter([
  {
    path: "",
    element: <RootElement />,
    children: [
      {
        index: true,
        element: <Navigate to="/organizations" />,
      },
      {
        path: "/organizations",
        element: <OrganizationsPage />,
      },
      {
        path: "/organization/:organizationId",
        element: <ResourcesPage />,
      },
      {
        path: "/organization/:organizationId/users",
        element: <UsersPage />,
      },
      {
        path: "/organization/:organizationId/resource/:resourceId",
        element: <VariablesPage />,
      },
    ],
  },
]);

export default MainRouter;
