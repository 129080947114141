import { useQueryClient } from "@tanstack/react-query";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import {
  EditOrganization,
  Icon,
  StatusComponent,
  TableButton,
} from "@/components/";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { QUERIES_KEYS } from "@/queries/queriesKeys";
import useNotificationStore from "@/store/useNotificationStore.store";
import { STATUS_TYPES } from "@/utils/consts";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { STATUS_KEYS } from "@/utils/utils";

import styles from "./styles.module.scss";

export const useOrganizationsColumns = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showDialog } = useDialog();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  const editButton = (api: RowClickedEvent) => {
    return (
      <TableButton
        onClick={(e) => {
          e.stopPropagation();
          const isStatusComplete =
            api.data.status !== Number(STATUS_KEYS[STATUS_TYPES[2]]);

          if (isStatusComplete) {
            queryClient.invalidateQueries({
              queryKey: [QUERIES_KEYS.organizations],
            });

            openNotification(
              t(LangKeys.NOTIFICATION_CREATE_NEW_ORGANIZATION_IN_PROGRESS),
              "info"
            );

            return;
          }
          showDialog(
            <EditOrganization
              organizationName={api.data.displayName}
              organizationId={api.data.id}
            />
          );
        }}
      >
        <Icon name="edit" className={styles.icon} />
      </TableButton>
    );
  };

  const columns: ColDef[] = [
    {
      headerName: t(LangKeys.ORGANIZATION_NAME),
      field: "displayName",
      sortable: true,
      cellDataType: "string",
      flex: 1,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.STATUS),
      field: "status",
      sortable: true,
      cellDataType: "string",
      flex: 1,
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellRenderer: (params: { value: keyof typeof STATUS_TYPES }) => (
        <div className={styles.customCell}>
          <StatusComponent status={params.value} />
        </div>
      ),
    },
    {
      headerName: t(LangKeys.ORGANIZATION_ID),
      field: "id",
      flex: 1,
      cellDataType: "string",
      sortable: true,
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.ORGANIZATION_ID),
      field: "permissionApi",
      flex: 1,
      hide: true,
      cellDataType: "string",
      sortable: true,
    },
    {
      headerName: "Edit",
      headerComponent: () => (
        <TableButton>
          <Icon name="edit" className={styles.icon} />
        </TableButton>
      ),
      field: "ediColumn",
      maxWidth: 120,
      cellRenderer: editButton,
      cellClass: "cell-style",
    },
  ];

  return columns;
};
