import { useTranslation } from "react-i18next";

import { DeleteVariableRequest } from "@/api/variablesSlice/types";
import { Button } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useDeleteVariable } from "@/queries/variablesQuery/variablesQuery";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

const DeleteVariableDialog = ({
  organizationId,
  resourceId,
  variableId,
}: DeleteVariableRequest) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { mutate, isPending } = useDeleteVariable();

  const handleDelete = () => {
    mutate({ organizationId, resourceId, variableId });
  };

  return (
    <div>
      <h3>{t(LangKeys.DELETE_VARIABLE)}</h3>

      <p>{t(LangKeys.ARE_YOU_SURE_YOU_WANT_DELETE_THIS_VARIABLE)}</p>

      <div className={styles.dialogFooter}>
        <Button type="button" variant="subtle" onClick={hideDialog}>
          {t(LangKeys.CANCEL)}
        </Button>

        <Button
          type="button"
          variant="primary"
          loading={isPending}
          onClick={() => handleDelete()}
        >
          {t(LangKeys.DELETE)}
        </Button>
      </div>
    </div>
  );
};

export default DeleteVariableDialog;
