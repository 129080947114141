import { Configuration } from "@azure/msal-browser";

export const config: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}/v2.0`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI ?? "", // Ensure this matches your redirect URI in Azure AD
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
