import { isNode, parse } from "mathjs";
import { useTranslation } from "react-i18next";

import { LangKeys } from "@/utils/i18n/languageKeys";

export const OperatorsList = () => {
  const { t } = useTranslation();

  return [
    { value: "+", label: t(LangKeys.ADDITIONAL) },
    { value: "-", label: t(LangKeys.SUBTRATION) },
    { value: "*", label: t(LangKeys.MULTIPLICATION) },
    { value: "/", label: t(LangKeys.DIVISION) },
    { value: "%", label: t(LangKeys.MODULO) },
    { value: "^", label: t(LangKeys.EXPONENTIATION) },
  ];
};

export const MATH_OPERATORS_LIST = ["+", "-", "*", "/", "^", "%"];

export const MATH_CONSTANTS_LIST = [
  { format: "pi", label: "π" },
  { format: "e", label: "e" },
];

export const MATH_FUNCTIONS_LIST = [
  { format: "sin(a)", label: "sin(a)" },
  { format: "cos(a)", label: "cos(a)" },
  { format: "sec(a)", label: "sec(a)" },
  { format: "csc(a)", label: "csc(a)" },
  { format: "tan(a)", label: "tan(a)" },
  { format: "cot(a)", label: "cot(a)" },
  { format: "asin(a)", label: "asin(a)" },
  { format: "acos(a)", label: "acos(a)" },
  { format: "atan(a)", label: "atan(a)" },
  { format: "acot(a)", label: "acot(a)" },
  { format: "loge(a)", label: "loge(a)" },
  { format: "log10(a)", label: "log10(a)" },
  { format: "logn(a; b)", label: "logn(a; b)" },
  { format: "sqrt(a)", label: "sqrt(a)" },
  { format: "abs(a)", label: "abs(a)" },
  { format: "if(a;b;c)", label: "if(a;b;c)" },
  { format: "ifless(a;b;c;d)", label: "ifless(a;b;c;d)" },
  { format: "ifmore(a;b;c;d)", label: "ifmore(a;b;c;d)" },
  { format: "ifequal(a;b;c;d)", label: "ifequal(a;b;c;d)" },
  { format: "ceiling(a)", label: "ceiling(a)" },
  { format: "floor(a)", label: "floor(a)" },
  { format: "truncate(a)", label: "truncate(a)" },
  { format: "round(a)", label: "round(a)" },
  { format: "max(a;b;...)", label: "max(a;b;...)" },
  { format: "min(a;b;...)", label: "min(a;b;...)" },
  { format: "avg(a;b;...)", label: "avg(a;b;...)" },
  { format: "median(a;b;...)", label: "median(a;b;...)" },
  { format: "sum(a;b;...)", label: "sum(a;b;...)" },
  { format: "random()", label: "random()" },
];

/**
 * Counts the number of elements (keys) in an object.
 *
 * @param {Object} obj - The object whose keys are to be counted. The object should have string keys and string values.
 * @returns {number} - The total number of keys in the object.
 *
 * @example
 * const exampleObject = { a: "1", b: "2", c: "3" };
 * const count = countObjectElements(exampleObject);
 * console.log(count); // Output: 3
 */
export const countObjectElements = (obj: { [key: string]: string }): number => {
  return Object.keys(obj).length;
};

/**
 * Validates the input expression.
 *
 * @param {string} expression - The expression to be validated.
 * @returns {boolean} - `true` if the expression is valid, `false` otherwise.
 */
export const validateExpression = (expression: string) => {
  try {
    const node = parse(expression);

    if (MATH_OPERATORS_LIST.includes(expression) || !isNode(node)) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Converts the variable code to variable name.
 *
 * @param {string} expression - The expression to be converted.
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @returns {string} - The converted expression.
 */
export const changeVariableCodeToVariableName = (
  expression: string,
  expressionInput: { [key: string]: string }
): string => {
  if (!expression || !expressionInput) {
    return "";
  }

  let newExpression = expression;

  Object.keys(expressionInput).forEach((key) => {
    newExpression = newExpression.replace(
      new RegExp(`\\b${expressionInput[key]}\\b`, "g"),
      key
    );
  });

  return newExpression;
};

/**
 * Checks if the variable is in the expression input.
 *
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @param {string} value - The value to be checked.
 * @returns {boolean} - `true` if the variable is in the expression input, `false` otherwise.
 */
export const checkVariableIsInExpressionInput = (
  expressionInput: { [key: string]: string },
  value: string
): boolean => {
  return Object.keys(expressionInput).some((key) => key === `${value}`);
};

/**
 * Adds a new variable to the expression input.
 *
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @param {string} value - The value to be added.
 * @returns {Object} - The updated expression input.
 */
export const addNewVariableToExpressionInput = (
  expressionInput: { [key: string]: string },
  value: string
): { [key: string]: string } => {
  const newExpressionInput = { ...expressionInput };

  if (checkVariableIsInExpressionInput(newExpressionInput, value)) {
    return newExpressionInput;
  }

  const newVariableName = value.replace(/[^a-zA-Z]/g, "");

  const uniqueKey = `${value}`;

  newExpressionInput[uniqueKey] = `VAR_${newVariableName}`;

  return newExpressionInput;
};

/**
 * Removes a variable from the expression input.
 *
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @param {string} value - The value to be removed.
 * @returns {Object} - The updated expression input.
 */
export const replaceVariableKeyToValueInExpressionByExpressionInput = (
  expression: string,
  expressionInput: { [key: string]: string }
): string => {
  let newExpression = expression;

  Object.keys(expressionInput).forEach((key) => {
    newExpression = newExpression.replace(
      new RegExp(`\\b${key}\\b`, "g"),
      expressionInput[key]
    );
  });

  return newExpression;
};

/**
 *
 * @param expression - The expression to be replaced.
 * @param expressionInput - The object containing the variable code and variable name.
 * @returns {string} - The updated expression.
 */
export const replaceVariableValueToKeyInExpressionByExpressionInput = (
  expression: string,
  expressionInput: { [key: string]: string }
): string => {
  let newExpression = expression;

  Object.keys(expressionInput).forEach((key) => {
    newExpression = newExpression.replace(
      new RegExp(`\\b${expressionInput[key]}\\b`, "g"),
      key
    );
  });

  return newExpression;
};

/**
 * Checks if the value is in the expression input.
 *
 * @param expressionInput - The object containing the variable code and variable name.
 * @param expression - The expression to be checked.
 * @returns {Object} - The updated expression input.
 */
export const checkValuesInObject = (
  expressionInput: { [key: string]: string },
  expression: string
) => {
  let result: { [key: string]: string } = {};

  for (const key in expressionInput) {
    if (Object.prototype.hasOwnProperty.call(expressionInput, key)) {
      if (!expression.includes(key)) {
        const deletedExpression = { ...expressionInput };
        delete deletedExpression[key];

        result = deletedExpression;
      }
    }
  }

  return result;
};
