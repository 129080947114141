import MOAlert from "@metsooutotec/modes-web-components/dist/react/alert";

import useNotificationStore from "@/store/useNotificationStore.store";

import styles from "./style.module.scss";

const Notification = () => {
  const { isOpen, message, closeNotification, variant } =
    useNotificationStore();

  if (!isOpen) return;

  return (
    <div className={styles.notificationBox}>
      <MOAlert
        variant={variant}
        duration={3000}
        open={isOpen}
        closable
        onMoAfterHide={() => closeNotification}
      >
        {message}
      </MOAlert>
    </div>
  );
};

export default Notification;
