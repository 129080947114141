import MOIconButton from "@metsooutotec/modes-web-components/dist/react/icon-button";
import MOTab from "@metsooutotec/modes-web-components/dist/react/tab";
import MOTabGroup from "@metsooutotec/modes-web-components/dist/react/tab-group";
import MOTabPanel from "@metsooutotec/modes-web-components/dist/react/tab-panel";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { DerivedPlantVariablesProvider } from "@/utils/DerivedPlantVariableProvider";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { PlantVariablesProvider } from "@/utils/PlantVariablesProvider";

import style from "./styles.module.scss";
import DerivedPlantVariableTab from "./Tabs/DerivedPlantVariableTab/DerivedPlantVariableTab";
import PlantVariableTab from "./Tabs/PlantVariableTab/PlantVariableTab";

const VariablesTabs = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className={style.variableHeader}>
        <MOIconButton
          name="arrow-left"
          className={style.headerButton}
          onClick={() => navigate(`/organization/${organizationId}`)}
        />

        <p>{t(LangKeys.VARIABLE)}</p>
      </div>

      <div className={style.variablePageContent}>
        <MOTabGroup className={style.tabGroup}>
          <MOTab slot="nav" panel="plant-variable">
            {t(LangKeys.PLANT_VARIABLE)}
          </MOTab>

          <MOTab slot="nav" panel="derived-plant-variable">
            {t(LangKeys.DERIVED_PLANT_VARIABLE)}
          </MOTab>

          <MOTabPanel name="plant-variable" className={style.tabPanel}>
            <PlantVariablesProvider>
              <PlantVariableTab />
            </PlantVariablesProvider>
          </MOTabPanel>

          <MOTabPanel name="derived-plant-variable" className={style.tabPanel}>
            <DerivedPlantVariablesProvider>
              <DerivedPlantVariableTab />
            </DerivedPlantVariablesProvider>
          </MOTabPanel>
        </MOTabGroup>
      </div>
    </>
  );
};

export default VariablesTabs;
