import { organizationApiClient } from "../apiClient";
import {
  AddResourceRequest,
  DeleteResourceRequest,
  EditResourceConfigurationRequest,
  EditResourceRequest,
  FetchResourceConfigurationRequest,
  FetchResourceRequest,
  FetchResourceStringIdRequest,
  Resource,
  ResourceConfigurationProps,
} from "./types";

export const fetchResources = async ({
  organizationId,
}: FetchResourceRequest): Promise<Resource[]> => {
  const response = await organizationApiClient.get(
    `/organizations/${organizationId}/resources`
  );
  return response.data;
};

export const fetchResourceStringId = async ({
  organizationId,
  resourceId,
}: FetchResourceStringIdRequest): Promise<string> => {
  const response = await organizationApiClient.get(
    `/organizations/${organizationId}/plants/${resourceId}/access`
  );

  return response.data;
};

export const fetchResourceConfiguration = async ({
  organizationId,
  resourceId,
}: FetchResourceConfigurationRequest): Promise<ResourceConfigurationProps> => {
  const response = await organizationApiClient.get(
    `organizations/${organizationId}/plants/${resourceId}/configuration`
  );

  return response.data;
};

export const editResourceConfiguration = async ({
  organizationId,
  resourceId,
  body,
}: EditResourceConfigurationRequest): Promise<ResourceConfigurationProps> => {
  const response = await organizationApiClient.put(
    `organizations/${organizationId}/plants/${resourceId}/configuration`,
    body
  );

  return response.data;
};

export const addResource = async ({
  organizationId,
  body,
}: AddResourceRequest): Promise<Resource> => {
  const response = await organizationApiClient.post(
    `/organizations/${organizationId}/resources`,
    JSON.stringify(body)
  );
  return response.data;
};

export const editResource = async ({
  organizationId,
  body,
}: EditResourceRequest): Promise<Resource> => {
  const response = await organizationApiClient.put(
    `/organizations/${organizationId}/resources/${body.id}`,
    body
  );
  return response.data;
};

export const deleteResource = async ({
  organizationId,
  resourceId,
}: DeleteResourceRequest): Promise<void> => {
  await organizationApiClient.delete(
    `/organizations/${organizationId}/resources/${resourceId}`
  );
};
