import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  LightUserData,
  Project,
  ProjectDTO,
  Role,
} from "@/api/projectSlice/types";
import { UpdateUserInformation } from "@/api/userSlice/types";
import Button from "@/components/Button";
import { useDialog } from "@/components/Dialog/DialogProvider";
import Input from "@/components/Input";
import { useProjects } from "@/queries/projectQuery/projectQuery";
import { useUpdateUser } from "@/queries/userQuery/userQuery";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./updateUser.module.scss";
import UpdateUserProjectSelect from "./updateUserProjectSelect";

type UpdateUserProps = {
  organizationId: string;
  userData: LightUserData;
  organizationProjects: Project[];
};

const UpdateUser = ({
  organizationId,
  userData,
  organizationProjects,
}: UpdateUserProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const [userProjects, setUserProjects] = useState<Project[]>([]);
  const [hasChanges, setHasChanges] = useState(false);

  const { data: orgData, isPending: isPendingProjects } = useProjects(
    organizationId ?? ""
  );
  const { mutate, isPending } = useUpdateUser();

  const originalUserProjects = useMemo(
    () =>
      orgData?.projects.filter((project) =>
        project.roles.some((role) =>
          role.assignedUsers?.some(
            (assignedUser) => assignedUser.id === userData.id
          )
        )
      ) ?? [],
    [orgData, userData.id]
  );

  const { handleSubmit } = useForm<UpdateUserInformation>({
    defaultValues: {
      userId: userData.id,
      organizationId,
      projects: [],
    },
  });

  const handleUpdateUser = async () => {
    const projects: ProjectDTO[] = userProjects
      .filter((project) => project !== undefined && project.id.length !== 0)
      .map((project) => {
        return {
          id: project.id,
          roles: project.roles
            .filter((role) =>
              role.assignedUsers?.some((u) => u.id === userData.id)
            )
            .map((role) => role.id),
        };
      });

    await mutate({
      userId: userData.id,
      organizationId,
      projects,
    });
  };

  const addProject = () => {
    setUserProjects([
      ...userProjects,
      {
        id: "",
        name: "",
        roles: [],
      },
    ]);
  };

  useEffect(() => {
    const isChanged =
      JSON.stringify(userProjects) !== JSON.stringify(originalUserProjects);
    setHasChanges(isChanged);
  }, [userProjects, originalUserProjects]);

  const selectProject = (
    index: number,
    projectName: string,
    projectId: string,
    roles: Role[] | undefined
  ) => {
    setUserProjects((userProjects) => {
      const updatedProjects = [...userProjects];
      if (updatedProjects[index]) {
        roles?.map((r) => r.assignedUsers.push(userData));
        updatedProjects[index] = {
          id: projectId,
          name: projectName,
          roles: roles ?? [],
        };
      }
      return updatedProjects;
    });
  };

  const removeProject = (index: number) => {
    const projects = [...userProjects];
    projects.splice(index, 1);
    setUserProjects(projects);
  };

  useEffect(() => {
    setUserProjects(originalUserProjects);
  }, [originalUserProjects]);

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.USER_DETAILS)}</h2>

      <form
        onSubmit={handleSubmit(handleUpdateUser)}
        className={styles.formBox}
      >
        <Input
          label={t(LangKeys.USER_EMAIL)}
          placeholder={userData.emailAddress}
          disabled
        />

        {!isPendingProjects &&
          userProjects.map((project, index) => (
            <UpdateUserProjectSelect
              key={project.id || `new-${index}`}
              index={index}
              projects={organizationProjects}
              userProjects={userProjects}
              userRolesForSelectedProject={project.roles.filter(
                (role) =>
                  role.assignedUsers?.findIndex(
                    (user) => user.id === userData.id
                  ) > -1
              )}
              selectedProject={project}
              onSelectProjectAndRoles={selectProject}
              onRemoveProject={removeProject}
            />
          ))}

        <div className={styles.buttonsBox}>
          <Button variant="subtle" onClick={addProject}>
            {t(LangKeys.ADD_PROJECT)}
          </Button>
          <Button variant="subtle" onClick={hideDialog}>
            {t(LangKeys.CANCEL)}
          </Button>
          <Button type="submit" loading={isPending} disabled={!hasChanges}>
            {t(LangKeys.CONFIRM)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateUser;
