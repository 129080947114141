import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  deleteUser,
  emailSearch,
  inviteUser,
  updateUser,
} from "@/api/userSlice/userSlice";
import { useDialog } from "@/components/Dialog/DialogProvider";
import useNotificationStore from "@/store/useNotificationStore.store";
import i18n from "@/utils/i18n/i18n";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { QUERIES_KEYS } from "../queriesKeys";

type ErrorType = Error & {
  response: {
    data: string;
  };
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: inviteUser,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.organizations, QUERIES_KEYS.users],
      });

      openNotification(
        i18n.t(LangKeys.NOTIFICATION_USER_INVITED_SUCCESS),
        "success"
      );
    },

    onError: () => {
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_SOMETHING_WENT_WRONG),
        "alert"
      );
    },
  });
};

export const useUpdateUser = () => {
  const { hideDialog } = useDialog();
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: updateUser,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.organizations, QUERIES_KEYS.users],
      });

      openNotification(
        i18n.t(LangKeys.NOTIFICATION_USER_UPDATED_SUCCESS),
        "success"
      );

      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useGetEmails = (emailSearchValue: string) => {
  return useQuery({
    queryKey: [emailSearchValue],
    queryFn: () => emailSearch(emailSearchValue),
  });
};

export const useDeleteUser = () => {
  const { hideDialog } = useDialog();
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: ({
      body,
      organizationId,
    }: {
      body: { userId: string; userEmail: string };
      organizationId: string;
    }) => deleteUser(body, organizationId),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.organizations, QUERIES_KEYS.users],
      });

      openNotification(
        i18n.t(LangKeys.NOTIFICATION_USER_DELETED_SUCCESS),
        "success"
      );

      hideDialog();
    },

    onError: () => {
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_SOMETHING_WENT_WRONG),
        "alert"
      );
    },
  });
};
