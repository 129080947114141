import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
} from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import Input from "@/components/Input";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

const FilterByValue = ({
  model,
  onModelChange,
  getValue,
}: CustomFilterProps) => {
  const refInput = useRef(null);
  const { t } = useTranslation();

  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const filterText: string = model;
      const value: string = getValue(node).toString().toLowerCase();
      return filterText
        .toLowerCase()
        .split(" ")
        .every((filterWord) => value.includes(filterWord));
    },
    [model]
  );

  const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
    if (!params || !params.suppressFocus) {
      setTimeout(() => {
        (refInput.current as HTMLInputElement | null)?.focus();
      }, 0);
    }
  }, []);

  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  return (
    <div className={styles.filterWrapper}>
      <Input
        ref={refInput}
        type="text"
        value={model ?? ""}
        onMoInput={({ target }) =>
          onModelChange((target as HTMLInputElement)?.value ?? null)
        }
        placeholder={t(LangKeys.VALUE_SEARCH)}
        autocomplete="off"
      ></Input>
    </div>
  );
};

export default FilterByValue;
