import { LangKeys } from "./i18n/languageKeys";
export const ROW_HEIGHT = 48;
export const INTERVAL_REFETCH = 10000;
export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = 3600;
export const ALPHABET_LETTERS_LIST = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

export const STATUS_TYPES = {
  0: "Unknown",
  1: "Creating",
  2: "Complete",
  3: "Removing",
  4: "Removed",
  9: "Error",
};

export const RESOURCE_PLANT_TYPE = {
  0: "Unknown",
  1: "Plant pipeline",
  2: "Geminex app",
};

export const IF_OUT_OF_RANGE = {
  discard: 0,
  zero: 1,
  previous: 2,
  clamp: 3,
};

export const IF_OUT_OF_RANGE_KEY = {
  [IF_OUT_OF_RANGE.discard]: "discard",
  [IF_OUT_OF_RANGE.zero]: "set0",
  [IF_OUT_OF_RANGE.previous]: "previousValue",
  [IF_OUT_OF_RANGE.clamp]: "clamp",
};

export const RECEIVING_TYPE = {
  discard: 0,
  continuous: 1,
  discrete: 2,
};

export const RECEIVING_TYPE_KEY = {
  [RECEIVING_TYPE.discard]: "discard",
  [RECEIVING_TYPE.continuous]: "continuous",
  [RECEIVING_TYPE.discrete]: "discrete",
};

export const OPTIONS_LISTS_KEYS = {
  IF_OUT_OF_RANGE: "IF_OUT_OF_RANGE",
  RECEIVING_TYPE: "RECEIVING_TYPE",
};

export const IfOutOfRangeList = [
  {
    name: LangKeys.DISCARD,
    value: IF_OUT_OF_RANGE.discard,
  },
  {
    name: LangKeys.SET_0,
    value: IF_OUT_OF_RANGE.zero,
  },
  {
    name: LangKeys.PREVIOUS_VALUE,
    value: IF_OUT_OF_RANGE.previous,
  },
  {
    name: LangKeys.CLAMP,
    value: IF_OUT_OF_RANGE.clamp,
  },
];

export const ReceivingTypeList = [
  {
    name: LangKeys.DISCARD,
    value: RECEIVING_TYPE.discard,
  },
  {
    name: LangKeys.CONTINUOUS,
    value: RECEIVING_TYPE.continuous,
  },
  {
    name: LangKeys.ON_CHANGE,
    value: RECEIVING_TYPE.discrete,
  },
];

export const CalculationTimeWindowList = [
  {
    name: LangKeys.MINUTES,
    value: "minutes",
  },
  {
    name: LangKeys.HOURS,
    value: "hours",
  },
  {
    name: LangKeys.DAY,
    value: "day",
  },
];

export const AveragingTypesList = [
  {
    name: LangKeys.TIME_BASED_AVERAGING,
    value: "time-based-averaging",
  },
  {
    name: LangKeys.SAMPLE_BASED_AVERAGING,
    value: "simple-based-averaging",
  },
];

export const DurationTypesList = [
  {
    name: LangKeys.SECONDS,
    value: "seconds",
  },
  {
    name: LangKeys.MINUTES,
    value: "minutes",
  },
  {
    name: LangKeys.HOURS,
    value: "hours",
  },
];
