import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import Dialog from "./Dialog";

type SizeDialogProps = "small" | "medium" | "large";

type DialogContextType = {
  showDialog: (
    dialogContent: ReactNode,
    sizeDialog?: SizeDialogProps,
    showHeader?: boolean
  ) => void;
  hideDialog: () => void;
  content: {
    children: ReactNode;
    sizeDialog?: SizeDialogProps;
    showHeader: boolean;
  } | null;
  isVisible: boolean;
};

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [content, setContent] = useState<DialogContextType["content"] | null>(
    null
  );
  const location = useLocation();

  const showDialog = (
    modalContent: ReactNode,
    sizeDialog: SizeDialogProps = "small",
    showHeader = false
  ) => {
    setContent({
      children: modalContent,
      sizeDialog,
      showHeader,
    });
    setIsVisible(true);
  };

  const hideDialog = () => {
    setIsVisible(false);
    setContent(null);
  };

  useEffect(() => {
    if (isVisible) {
      hideDialog();
    }
  }, [location]);

  return (
    <DialogContext.Provider
      value={{ showDialog, hideDialog, content, isVisible }}
    >
      {children}
      {isVisible && <Dialog />}
    </DialogContext.Provider>
  );
};

export function useDialog() {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
}
