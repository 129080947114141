import React, { createContext, ReactNode, useContext, useState } from "react";

type DerivedPlantVariablesContextType = {
  derivedPlantVariablesToEdit: string[];
  setDerivedPlantVariablesToEdit: (variables: string[]) => void;
};

const DerivedPlantVariablesContext = createContext<
  DerivedPlantVariablesContextType | undefined
>(undefined);

export const useDerivedPlantVariablesContext = () => {
  const context = useContext(DerivedPlantVariablesContext);
  if (!context) {
    throw new Error(
      "useDerivedPlantVariablesContext must be used within a DerivedPlantVariablesProvider"
    );
  }
  return context;
};

type DerivedPlantVariablesProviderProps = {
  children: ReactNode;
};

export const DerivedPlantVariablesProvider: React.FC<
  DerivedPlantVariablesProviderProps
> = ({ children }) => {
  const [derivedPlantVariablesToEdit, setDerivedPlantVariablesToEdit] =
    useState<string[]>([]);

  return (
    <DerivedPlantVariablesContext.Provider
      value={{ derivedPlantVariablesToEdit, setDerivedPlantVariablesToEdit }}
    >
      {children}
    </DerivedPlantVariablesContext.Provider>
  );
};
