import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { useCallback } from "react";

import {
  IfOutOfRangeList,
  OPTIONS_LISTS_KEYS,
  ReceivingTypeList,
} from "@/utils/consts";

import styles from "./styles.module.scss";

type FilterByListProps = CustomFilterProps & {
  filterOptions: { name: string; value: number }[];
};

const FilterByList = ({
  model,
  onModelChange,
  getValue,
  filterOptions,
}: FilterByListProps) => {
  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const value = getValue(node);
      return model === null || value === model;
    },
    [model]
  );

  useGridFilter({
    doesFilterPass,
  });

  const handleCheckboxChange = (value: number) => {
    if (model === value) {
      onModelChange(null);
    } else {
      onModelChange(value);
    }
  };

  return (
    <div className={styles.filterWrapper}>
      {filterOptions.map(({ name, value }) => (
        <label key={value} className={styles.customLabel}>
          <input
            type="checkbox"
            name="filter"
            value={value}
            checked={model === value}
            onChange={() => handleCheckboxChange(value)}
            className={styles.checkboxInput}
          />
          <span className={styles.customCheckbox}>{name}</span>
        </label>
      ))}
    </div>
  );
};

export default FilterByList;

export const getFilterOptions = (
  filterType: keyof typeof OPTIONS_LISTS_KEYS
) => {
  switch (filterType) {
    case OPTIONS_LISTS_KEYS.IF_OUT_OF_RANGE:
      return IfOutOfRangeList;
    case OPTIONS_LISTS_KEYS.RECEIVING_TYPE:
      return ReceivingTypeList;
    default:
      return [];
  }
};
