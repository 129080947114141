import { useMsal } from "@azure/msal-react";
import MODivider from "@metsooutotec/modes-web-components/dist/react/divider";
import MOHeader from "@metsooutotec/modes-web-components/dist/react/header";
import MOHeaderMenu from "@metsooutotec/modes-web-components/dist/react/header-menu";
import MOMenuItem from "@metsooutotec/modes-web-components/dist/react/menu-item";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useOrganizations } from "@/queries/organizationsQuery/organizationsQuery";
import { useResources } from "@/queries/resourcesQuery/resourcesQuery";
import { Languages, languagesShorts } from "@/utils/i18n/i18n";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { localStorageUtils } from "@/utils/localStorageUtils";

import Breadcrumbs from "../Breadcrumbs";
import Icon from "../Icon";
import styles from "./header.module.scss";

const Header = () => {
  const { organizationId, resourceId } = useParams();
  const { instance } = useMsal();
  const { i18n, t } = useTranslation();

  const { data: organizationData } = useOrganizations();
  const { data: resourceData } = useResources(organizationId ?? "");

  const handleLogout = () => {
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  };

  const changeLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang);
    localStorageUtils.setLanguageSelection(lang);
  };

  const organizationName = organizationData?.find(
    (organization) => organization.id === organizationId
  )?.displayName;

  const resourceName = resourceData?.find(
    (resource) => resource.resourceId === resourceId
  )?.displayName;

  const breadcrumbsList = useMemo(
    () => [
      { name: t(LangKeys.ORGANIZATIONS), url: "/organizations" },
      {
        name: organizationName ?? "",
        url: `/organization/${organizationId}`,
      },
      {
        name: resourceName ?? "",
        url: `/organization/${organizationId}/resource/${resourceId}`,
      },
    ],
    [t, organizationName, organizationId, resourceName, resourceId]
  );

  return (
    <>
      <MOHeader noHamburgerMenu title="Geminex™">
        <MODivider vertical slot="left-menu" className={styles.divider} />

        <Breadcrumbs links={breadcrumbsList} slot="left-menu" />

        <MOHeaderMenu
          label={languagesShorts[i18n.language as Languages]}
          slot="right-menu"
        >
          <MOMenuItem onClick={() => changeLanguage("en")}>English</MOMenuItem>
          <MOMenuItem onClick={() => changeLanguage("fn")}>Suomi</MOMenuItem>
        </MOHeaderMenu>

        <MODivider vertical slot="right-menu" className={styles.divider} />

        <MOHeaderMenu noCaret iconName="person" slot="right-menu">
          <MOMenuItem onClick={handleLogout}>
            <Icon name="logout" />
            {t(LangKeys.LOGOUT)}
          </MOMenuItem>
        </MOHeaderMenu>
      </MOHeader>
    </>
  );
};

export default Header;
