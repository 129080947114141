import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { RouterProvider } from "react-router-dom";

import { Notification } from "./components";
import { LoginPage } from "./pages";
import MainRouter from "./router/MainRouter";

function App() {
  return (
    <>
      <Notification />

      <AuthenticatedTemplate>
        <RouterProvider router={MainRouter} />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
