import MOBadge from "@metsooutotec/modes-web-components/dist/react/badge";

import { STATUS_TYPES } from "@/utils/consts";

// TODO: FIx the prettier error
// Some problem with prettier and prettier wants to delete <typeof MOBadge>
// eslint-disable-next-line prettier/prettier
type MOBadgeProps = React.ComponentProps<typeof MOBadge>;
type MOBadgeVariant = MOBadgeProps["variant"];

type StatusComponentProps = {
  status: keyof typeof STATUS_TYPES;
};

export const badgeStatus = {
  0: "primary",
  1: "success",
  2: "info",
  3: "warning",
  4: "inactive",
  9: "alert",
};

const StatusComponent = ({ status }: StatusComponentProps) => {
  return (
    <MOBadge variant={badgeStatus[status] as MOBadgeVariant} pill>
      {STATUS_TYPES[status]}
    </MOBadge>
  );
};

export default StatusComponent;
