import { vestResolver } from "@hookform/resolvers/vest";
import MOInput from "@metsooutotec/modes-web-components/dist/react/input";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { AddNewResource } from "@/api/resourceSlice/types";
import { Button } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useAddResource } from "@/queries/resourcesQuery/resourcesQuery";
import { RESOURCE_PLANT_TYPE } from "@/utils/consts";
import {
  inputIsNotEmpty,
  inputNoUpperCase,
} from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { resourcePlantTypeConvert } from "@/utils/resourcePlantTypeConvert";

import styles from "./createnewresource.module.scss";

const schema = create((data: AddNewResource["body"]) => {
  inputIsNotEmpty("displayName", data.displayName);
  inputIsNotEmpty("id", data.id);
  inputNoUpperCase("id", data.id);
  inputIsNotEmpty("type", data.$type);
});

const selectOptions = resourcePlantTypeConvert(RESOURCE_PLANT_TYPE);

type CreateNewResourceProps = {
  organizationId: string;
};

const CreateNewResource = ({ organizationId }: CreateNewResourceProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { mutate, isSuccess } = useAddResource();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddNewResource["body"]>({
    defaultValues: {
      displayName: "",
      id: "",
    },
    resolver: vestResolver(schema),
  });

  const handleCreateResource = async (data: AddNewResource["body"]) => {
    await mutate({
      organizationId: organizationId,
      body: {
        $type: Number(data.$type),
        displayName: data.displayName,
        id: data.id,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      hideDialog();
    }
  }, [isSuccess, hideDialog]);

  const optionsList = selectOptions.map((option) => (
    <MOOption key={option.value} value={option.value}>
      {option.label}
    </MOOption>
  ));

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.ADD_NEW_RESOURCE)}</h2>

      <form
        onSubmit={handleSubmit(handleCreateResource)}
        className={styles.formBox}
      >
        <div className={styles.inputBox}>
          <MOInput
            label={t(LangKeys.RESOURCE_NAME)}
            placeholder={t(LangKeys.RESOURCE_NAME)}
            error={errors.displayName !== undefined}
            autocomplete="off"
            {...register("displayName")}
          />

          {errors.displayName && (
            <p className={styles.errorText}>{errors.displayName?.message}</p>
          )}
        </div>

        <div className={styles.inputBox}>
          <MOInput
            label={t(LangKeys.RESOURCE_ID_SMALL)}
            placeholder={t(LangKeys.RESOURCE_ID_SMALL)}
            error={errors.id !== undefined}
            autocomplete="off"
            {...register("id")}
          />

          {errors.id && (
            <p className={styles.errorText}>{errors.id?.message}</p>
          )}
        </div>

        <div>
          <MOSelect
            className={styles.select}
            data-testid="title-label-alignment-select"
            label={t(LangKeys.RESOURCE_TYPE)}
            placeholder={t(LangKeys.RESOURCE_TYPE)}
            onMoAfterHide={(e: any) => e.stopPropagation()}
            error={errors.$type !== undefined}
            {...register("$type")}
          >
            {optionsList}
          </MOSelect>

          {errors.$type?.message && (
            <p className={styles.errorText}>{errors.$type?.message}</p>
          )}
        </div>

        <div className={styles.buttonsBox}>
          <Button
            variant="subtle"
            className={styles.buttons}
            onClick={hideDialog}
          >
            {t(LangKeys.CANCEL)}
          </Button>

          <Button className={styles.buttons} type="submit">
            {t(LangKeys.CONFIRM)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateNewResource;
