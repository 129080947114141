import { GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { VariableProps } from "@/api/variablesSlice/types";
import { DataGrid } from "@/components";
import { useGetVariables } from "@/queries/variablesQuery/variablesQuery";
import { usePlantVariablesContext } from "@/utils/PlantVariablesProvider";

import usePlantVariableColumns from "./usePlantVariableColumns";

const variableConfigurationExist = (variable: VariableProps) =>
  variable?.configuration === null ||
  (variable?.configuration?.expression !== undefined &&
    variable.configuration.expression.trim() === "");

const PlantVariableTable = () => {
  const { organizationId, resourceId } = useParams();
  const { setPlantVariablesToEdit } = usePlantVariablesContext();

  const { data: resourceVariablesData, isPending } = useGetVariables({
    organizationId,
    resourceId,
  });

  const columnsDef = usePlantVariableColumns({ organizationId, resourceId });

  const data = useMemo(() => {
    return resourceVariablesData
      ? resourceVariablesData
          .filter((variable) => variableConfigurationExist(variable))
          .map((variable) => ({
            ...variable.configuration,
            variableConfig: variable.configuration,
            variableId: variable.variableName,
            variableName: variable.variableName,
            outOfRange: variable.configuration
              ? variable.configuration.invalidMeasurementHandling
              : null,
            receivingType: variable.configuration
              ? variable.configuration.type
              : null,
          }))
      : [];
  }, [resourceVariablesData]);

  const handleUpdateSelectedRows = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows() ?? [];

    setPlantVariablesToEdit(selectedRows.map((row) => row.variableId));
  };

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: columnsDef,
      rowSelection: "multiple",
      onSelectionChanged: handleUpdateSelectedRows,
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: false,
      },
    }),
    [columnsDef]
  );

  return (
    <DataGrid rowData={data} gridOptions={gridOptions} loading={isPending} />
  );
};

export default PlantVariableTable;
