import { Outlet } from "react-router-dom";

import { DialogProvider } from "../Dialog/DialogProvider";
import Header from "../Header";
import styles from "./styles.module.scss";

const RootElement = () => {
  return (
    <DialogProvider>
      <Header />

      <main className={styles.main}>
        <Outlet />
      </main>
    </DialogProvider>
  );
};

export default RootElement;
