import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { localStorageUtils } from "../localStorageUtils";
import en_form from "./locales/en/en_form.json";
import en_hints from "./locales/en/en_hints.json";
import en_main from "./locales/en/en_main.json";
import en_notifications from "./locales/en/en_notifications.json";
import fn_form from "./locales/fn/fn_form.json";
import fn_hints from "./locales/fn/fn_hints.json";
import fn_main from "./locales/fn/fn_main.json";
import fn_notifications from "./locales/fn/fn_notifications.json";

export type Languages = "en" | "fn";

export const languagesShorts = {
  en: "English",
  fn: "Suomi",
};

const english = {
  ...en_main,
  ...en_hints,
  ...en_form,
  ...en_notifications,
};

const finnish = {
  ...fn_main,
  ...fn_hints,
  ...fn_form,
  ...fn_notifications,
};

const resources = {
  en: { translation: english },
  fn: { translation: finnish },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorageUtils.getLanguageSelection() || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
