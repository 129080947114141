import { vestResolver } from "@hookform/resolvers/vest";
import MOInput from "@metsooutotec/modes-web-components/dist/react/input";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { Organization } from "@/api/organizationSlice/types";
import Button from "@/components/Button";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useEditOrganization } from "@/queries/organizationsQuery/organizationsQuery";
import { inputIsNotEmpty } from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./editorganization.module.scss";

const schema = create((data: Organization) => {
  inputIsNotEmpty(data.displayName, "Organization Name is required");
});

type EditOrganizationProps = {
  organizationName: string;
  organizationId: string;
};

const EditOrganization = ({
  organizationName,
  organizationId,
}: EditOrganizationProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { mutate, isSuccess } = useEditOrganization();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Organization>({
    defaultValues: {
      displayName: organizationName,
      id: organizationId,
    },
    resolver: vestResolver(schema),
  });

  const handleCreateOrganization = async (data: Organization) => {
    await mutate({ id: data.id, data });
  };

  useEffect(() => {
    if (isSuccess) {
      hideDialog();
    }
  }, [isSuccess, hideDialog]);

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.ORGANIZATION_DETAILS)}</h2>

      <form
        onSubmit={handleSubmit(handleCreateOrganization)}
        className={styles.formBox}
      >
        <div>
          <MOInput
            label={t(LangKeys.ORGANIZATION_NAME)}
            placeholder={t(LangKeys.ORGANIZATION_NAME)}
            error={errors.displayName !== undefined}
            {...register("displayName")}
          />
          {errors.displayName && (
            <p className={styles.errorText}>{errors.displayName.message}</p>
          )}
        </div>

        <div className={styles.buttonsBox}>
          <Button
            variant="subtle"
            className={styles.buttons}
            onClick={hideDialog}
          >
            {t(LangKeys.CANCEL)}
          </Button>

          <Button className={styles.buttons} type="submit">
            {t(LangKeys.CONFIRM)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditOrganization;
