import { organizationApiClient } from "../apiClient";
import { OrganizationData } from "../organizationSlice/types";
import { FetchProjectsRequest } from "./types";

export const fetchProjects = async ({
  organizationId,
}: FetchProjectsRequest): Promise<OrganizationData> => {
  const response = await organizationApiClient.get(
    `/adminmanagement/${organizationId}/all`
  );

  return response.data;
};
