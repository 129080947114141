import React, { createContext, ReactNode, useContext, useState } from "react";

type PlantVariablesContextType = {
  plantVariablesToEdit: string[];
  setPlantVariablesToEdit: (variables: string[]) => void;
};

const PlantVariablesContext = createContext<
  PlantVariablesContextType | undefined
>(undefined);

export const usePlantVariablesContext = () => {
  const context = useContext(PlantVariablesContext);
  if (!context) {
    throw new Error(
      "usePlantVariablesContext must be used within a PlantVariablesProvider"
    );
  }
  return context;
};

type PlantVariablesProviderProps = {
  children: ReactNode;
};

export const PlantVariablesProvider = ({
  children,
}: PlantVariablesProviderProps) => {
  const [plantVariablesToEdit, setPlantVariablesToEdit] = useState<string[]>(
    []
  );

  return (
    <PlantVariablesContext.Provider
      value={{ plantVariablesToEdit, setPlantVariablesToEdit }}
    >
      {children}
    </PlantVariablesContext.Provider>
  );
};
