import { vestResolver } from "@hookform/resolvers/vest";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { Organization } from "@/api/organizationSlice/types";
import Button from "@/components/Button";
import Input from "@/components/Input";
import { useAddOrganization } from "@/queries/organizationsQuery/organizationsQuery";
import { inputIsNotEmpty } from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { useDialog } from "../../Dialog/DialogProvider";
import styles from "./createneworganization.module.scss";

const schema = create((data: Organization) => {
  inputIsNotEmpty("displayName", data.displayName);
  inputIsNotEmpty("id", data.id);
});

const CreateNewOrganization = () => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { mutate, isSuccess, isPending } = useAddOrganization();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Organization>({
    defaultValues: {
      displayName: "",
      id: "",
    },
    resolver: vestResolver(schema),
  });

  const handleCreateOrganization = async (data: Organization) => {
    await mutate({
      body: {
        id: data.id.toLowerCase(),
        displayName: data.displayName,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      hideDialog();
    }
  }, [isSuccess, hideDialog]);

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.ORGANIZATION_DETAILS)}</h2>

      <form
        onSubmit={handleSubmit(handleCreateOrganization)}
        className={styles.formBox}
      >
        <Input
          label={t(LangKeys.ORGANIZATION_NAME)}
          placeholder={t(LangKeys.ORGANIZATION_NAME)}
          error={errors.displayName !== undefined}
          errorText={errors.displayName?.message}
          autocomplete="off"
          {...register("displayName")}
        />

        <Controller
          control={control}
          name={"id"}
          render={({ field }) => {
            return (
              <Input
                label={t(LangKeys.ORGANIZATION_ID)}
                placeholder={t(LangKeys.ORGANIZATION_ID)}
                error={errors.id !== undefined}
                errorText={errors.id?.message}
                hint={t(LangKeys.HINT_CUSTOMER_ID_FROM_SALESFORCE)}
                autocomplete="off"
                value={field.value}
                onMoInput={(event) => {
                  field.onChange(
                    (event.target as HTMLInputElement).value.toLowerCase()
                  );
                }}
              />
            );
          }}
        />

        <div className={styles.buttonsBox}>
          <Button
            variant="subtle"
            className={styles.buttons}
            onClick={hideDialog}
          >
            {t(LangKeys.CANCEL)}
          </Button>

          <Button className={styles.buttons} type="submit" loading={isPending}>
            {t(LangKeys.CONFIRM)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateNewOrganization;
