import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  Button,
  DerivedPlantVariableTable,
  EditDerivedVariablesDialog,
  Icon,
} from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import AddNewDerivedVariable from "@/components/Forms/AddNewDerivedVariable/AddNewDerivedVariable";
import { useDerivedPlantVariablesContext } from "@/utils/DerivedPlantVariableProvider";
import { LangKeys } from "@/utils/i18n/languageKeys";

import style from "./styles.module.scss";

const DerivedPlantVariableTab = () => {
  const { derivedPlantVariablesToEdit } = useDerivedPlantVariablesContext();
  const { organizationId, resourceId } = useParams();
  const { t } = useTranslation();
  const { showDialog } = useDialog();

  return (
    <div>
      <div className={style.buttonsGroup}>
        <Button
          name="edit"
          variant="secondary"
          disabled={!(derivedPlantVariablesToEdit.length > 0)}
          onClick={() =>
            showDialog(
              <EditDerivedVariablesDialog
                organizationId={organizationId}
                resourceId={resourceId}
                derivedPlantVariablesToEdit={derivedPlantVariablesToEdit}
              />,
              "medium"
            )
          }
        >
          <Icon slot="prefix" name="edit"></Icon>

          {t(LangKeys.EDIT_VARIABLES)}
        </Button>

        <Button
          name="add"
          onClick={() =>
            showDialog(
              <AddNewDerivedVariable
                organizationId={organizationId}
                resourceId={resourceId}
              />,
              "medium"
            )
          }
        >
          <Icon slot="prefix" name="plus"></Icon>

          {t(LangKeys.ADD_NEW_VARIABLE)}
        </Button>
      </div>

      <DerivedPlantVariableTable />
    </div>
  );
};

export default DerivedPlantVariableTab;
