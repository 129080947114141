import MOIconButton from "@metsooutotec/modes-web-components/dist/react/icon-button";
import MOInput from "@metsooutotec/modes-web-components/dist/react/input";
import MOTooltip from "@metsooutotec/modes-web-components/dist/react/tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

type CopyButtonInputProps = {
  value: string;
  disabled?: boolean;
  label: string;
};

const CopyButtonInput = ({ value, disabled, label }: CopyButtonInputProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  const handleCopy = () => {
    if (value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000);
        })
        .catch((err) => {
          console.error("Copy failed", err);
        });
    }
  };

  return (
    <div className={styles.dataInput}>
      <MOInput
        id="resource-id"
        label={label}
        value={value}
        disabled={disabled}
        size="large"
      />

      <MOTooltip
        onClick={handleCopy}
        placement="top"
        content={isCopied ? t(LangKeys.COPIED) : t(LangKeys.COPY_INPUT)}
        hoist
        onMoAfterHide={(e) => e.stopPropagation()}
      >
        <MOIconButton name="copy" />
      </MOTooltip>
    </div>
  );
};
export default CopyButtonInput;
