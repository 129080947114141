import * as msal from "@azure/msal-browser";

import { loginRequest } from "@/authConfig";
import { msalInstance } from "@/index";

/**
 * Singleton class for accessing the auth token
 */
export class AuthService {
  static instance: AuthService = new AuthService();

  private token: string | null;

  constructor() {
    this.token = null;
  }

  /**
   * If the token is not set yet, it will try to acquire the token silently
   * using the `acquireToken()` method.
   *
   * Returns the token or null
   */
  public async getToken(): Promise<string | null> {
    if (!this.token) {
      await this.acquireToken();
    }

    return this.token;
  }

  /**
   * Tries to acquire the token silently using msal API, if the silent token
   * acquisition fails, the end user needs to re-login again.
   *
   * After token acquisition we store the token in the AuthService.token
   */
  public async acquireToken() {
    // Get the first active account
    const activeAccount = msalInstance.getAllAccounts()[0];
    if (activeAccount) {
      msalInstance.setActiveAccount(activeAccount);

      try {
        const authResult = await msalInstance.acquireTokenSilent({
          ...loginRequest,
        });
        this.token = authResult.idToken;
      } catch (error) {
        console.error("Token acquisition failed:", error);
        if (error instanceof msal.InteractionRequiredAuthError) {
          // Fallback to interactive method
          await msalInstance.acquireTokenRedirect(loginRequest);
        }
      }
    } else {
      // If no account was given we need to re-authenticate the user
      await msalInstance.acquireTokenRedirect(loginRequest);
    }
  }

  static getInstance(): AuthService {
    return AuthService.instance;
  }
}
