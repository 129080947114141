import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LightUserData } from "@/api/projectSlice/types";
import { Button } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useDeleteUser } from "@/queries/userQuery/userQuery";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

type DeleteUserProps = {
  organizationId: string;
  userData: LightUserData;
};

const DeleteUser = ({ organizationId, userData }: DeleteUserProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();

  const { mutate, isPending } = useDeleteUser();

  const { handleSubmit } = useForm({
    defaultValues: {
      userId: userData.id,
      organizationId,
    },
  });

  const handleDelete = () => {
    mutate({
      organizationId,
      body: {
        userId: userData.id,
        userEmail: userData.emailAddress,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleDelete)}>
      <h2>{t(LangKeys.DELETE_USER)}</h2>

      <p>
        {t(LangKeys.DELETE_USER_CONFIRMATION, {
          name: userData.fullName,
        })}
      </p>

      <div className={styles.buttonsBox}>
        <Button variant="subtle" onClick={hideDialog}>
          {t(LangKeys.CANCEL)}
        </Button>

        <Button type="submit" loading={isPending}>
          {t(LangKeys.CONFIRM)}
        </Button>
      </div>
    </form>
  );
};

export default DeleteUser;
