import { GridOptions } from "ag-grid-community";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  BackButton,
  Button,
  DataGrid,
  Icon,
  InviteNewUser,
  LoadingSpinner,
} from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useProjects } from "@/queries/projectQuery/projectQuery";
import useNotificationStore from "@/store/useNotificationStore.store";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { sortUsers } from "@/utils/utils";

import styles from "./styles.module.scss";
import useUsersColumns from "./useUsersColumns";

const UsersPage = () => {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { t } = useTranslation();
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const {
    data: orgData,
    isError: projectsError,
    isPending,
  } = useProjects(organizationId ?? "");
  const projects = orgData?.projects;
  const users = orgData?.users;

  const sortedUsers = sortUsers(users ?? []);

  const columns = useUsersColumns({ organizationId, projects, orgData });

  useEffect(() => {
    if (projectsError)
      openNotification(t(LangKeys.NOTIFICATION_SOMETHING_WENT_WRONG), "alert");
  }, [projectsError]);

  const onInviteUser = () => {
    if (organizationId)
      showDialog(
        <InviteNewUser organizationId={organizationId} projects={projects} />
      );
  };

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: columns,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: false,
      },
    }),
    [orgData, t, columns]
  );

  return (
    <div className={styles.usersPage}>
      <h5 className={styles.title}>{t(LangKeys.USERS)}</h5>

      <div>
        <BackButton onClick={() => navigate(`/organization/${organizationId}`)}>
          {t(LangKeys.BACK)}
        </BackButton>
      </div>

      {isPending ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className={styles.buttonWrapper}>
            <Button onClick={onInviteUser}>
              <Icon
                className={styles.buttonIcon}
                name="person-add"
                slot="prefix"
              />

              {t(LangKeys.INVITE_USER)}
            </Button>
          </div>

          <DataGrid gridOptions={gridOptions} rowData={sortedUsers ?? []} />
        </div>
      )}
    </div>
  );
};

export default UsersPage;
