import MOSpinner from "@metsooutotec/modes-web-components/dist/react/spinner";

import styles from "./styles.module.scss";

const LoadingSpinner = () => {
  return (
    <div className={styles.loadingBox}>
      <MOSpinner />
    </div>
  );
};

export default LoadingSpinner;
