import MOButton from "@metsooutotec/modes-web-components/dist/react/button";

import Button from "../Button";
import styles from "./styles.module.scss";

type EditButtonProps = {
  children: React.ReactNode;
} & React.ComponentProps<typeof MOButton>;

const TableButton = ({ children, ...props }: EditButtonProps) => {
  return (
    <Button
      type="button"
      className={styles.editButton}
      variant="subtle"
      {...props}
    >
      {children}
    </Button>
  );
};

export default TableButton;
