import MODialog from "@metsooutotec/modes-web-components/dist/react/dialog";
import cn from "classnames";

import { useDialog } from "./DialogProvider";
import styles from "./styles.module.scss";

const Dialog = () => {
  const { content, hideDialog, isVisible } = useDialog();

  return (
    <MODialog
      noHeader
      open={isVisible}
      onMoRequestClose={hideDialog}
      onMoAfterHide={hideDialog}
      className={cn(styles.dialog_width_small, {
        [styles.dialog_width_medium]: content?.sizeDialog === "medium",
        [styles.dialog_width_large]: content?.sizeDialog === "large",
      })}
    >
      {content?.children}
    </MODialog>
  );
};

export default Dialog;
