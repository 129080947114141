import { useQueryClient } from "@tanstack/react-query";
import {
  GridOptions,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  CreateNewOrganization,
  DataGrid,
  DeleteOrganization,
  Icon,
  LoadingSpinner,
} from "@/components/";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useOrganizations } from "@/queries/organizationsQuery/organizationsQuery";
import { QUERIES_KEYS } from "@/queries/queriesKeys";
import useNotificationStore from "@/store/useNotificationStore.store";
import { STATUS_TYPES } from "@/utils/consts";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { STATUS_KEYS } from "@/utils/utils";

import styles from "./styles.module.scss";
import { useOrganizationsColumns } from "./useOrganizationColumns";

const OrganizationsPage = () => {
  const queryClient = useQueryClient();
  const columns = useOrganizationsColumns();
  const { data, isLoading } = useOrganizations();
  const [isActive, setIsActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [gridData, setGridData] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showDialog } = useDialog();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  useEffect(() => {
    if (data) {
      // @ts-ignore
      setGridData(data);
    }
  }, [data]);

  const selectedOrganization = (params: RowDoubleClickedEvent) => {
    if (`${params.data.status}` !== STATUS_KEYS[STATUS_TYPES[2]]) {
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.organizations] });
      openNotification(
        t(LangKeys.NOTIFICATION_CREATE_NEW_ORGANIZATION_IN_PROGRESS),
        "info"
      );

      return;
    }

    navigate(`/organization/${params.data.id}`);
  };

  const handleUpdateSelectedRows = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows() ?? [];
    const selectedRowsNotEmpty = selectedRows.length > 0;

    setIsActive(selectedRowsNotEmpty);
    setSelectedRows(selectedRows);
  };

  const openCreateNewOrganization = () => {
    showDialog(<CreateNewOrganization />);
  };

  const onDeleteSelected = () => {
    showDialog(<DeleteOrganization organizations={selectedRows} />, "large");
  };

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: columns,
      rowSelection: "multiple",
      onSelectionChanged: handleUpdateSelectedRows,
      onRowDoubleClicked: selectedOrganization,
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: false,
      },
    }),
    [columns, handleUpdateSelectedRows, selectedOrganization, gridData]
  );

  const isData = gridData && gridData.length > 0;

  return (
    <div className={styles.organizationPage}>
      <h1 className={styles.title}>{t(LangKeys.SELECT_YOUR_ORGANIZATION)}</h1>

      <div className={styles.deleteOrganizationBox}>
        <Button
          disabled={!isActive}
          variant="secondary"
          className={styles.deletebutton}
          onClick={onDeleteSelected}
        >
          <Icon className={styles.buttonIcon} name="minus" />
          {t(LangKeys.DELETE_ORGANIZATION)}
        </Button>
      </div>

      {isLoading ? (
        <LoadingSpinner />
      ) : isData ? (
        <DataGrid rowData={gridData} gridOptions={gridOptions} />
      ) : (
        <>
          <p className={styles.noOrganization}>
            {t(LangKeys.NO_ORGANIZATION_HAS_BEEN_ADDED_YET)}
          </p>
        </>
      )}

      <div className={styles.createNewOrganizationBox}>
        <Button onClick={openCreateNewOrganization}>
          {t(LangKeys.CREATE_NEW_ORGANIZATION)}
        </Button>
      </div>
    </div>
  );
};

export default OrganizationsPage;
