import axios from "axios";

import { AuthService } from "@/auth";

// Create an Axios instance for organization data
const organizationApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Adjust base URL as needed
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

// Intercepts every request and adds the Authorization header with the
// JWT coming from the Auth Service
organizationApiClient.interceptors.request.use(
  async (config) => {
    let token = await AuthService.getInstance().getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export { organizationApiClient };
