import { organizationApiClient } from "../apiClient";
import {
  AddNewVariableRequest,
  DeleteVariableRequest,
  EditDerivedVariablesRequest,
  EditVariableRequest,
  GetAllVariablesRequest,
  VariableProps,
} from "./types";

export const getAllVariables = async ({
  organizationId,
  resourceId,
}: GetAllVariablesRequest): Promise<VariableProps[]> => {
  const response = await organizationApiClient.get(
    `organizations/${organizationId}/plants/${resourceId}/variables`
  );

  return response.data;
};

export const addNewVariable = async ({
  organizationId,
  resourceId,
  body,
}: AddNewVariableRequest) => {
  const response = await organizationApiClient.post(
    `organizations/${organizationId}/plants/${resourceId}/variables`,
    body
  );

  return response.data;
};

export const editVariablesConfiguration = async ({
  organizationId,
  resourceId,
  body,
}: EditVariableRequest) => {
  const response = await organizationApiClient.put(
    `organizations/${organizationId}/plants/${resourceId}/variables`,
    body
  );

  return response.data;
};

export const editDerivedVariablesConfiguration = async ({
  organizationId,
  resourceId,
  body,
}: EditDerivedVariablesRequest) => {
  const response = await organizationApiClient.put(
    `organizations/${organizationId}/plants/${resourceId}/variables`,
    body
  );

  return response.data;
};

export const deleteVariable = async ({
  organizationId,
  resourceId,
  variableId,
}: DeleteVariableRequest) => {
  const response = await organizationApiClient.delete(
    `organizations/${organizationId}/plants/${resourceId}/variables/${variableId}`
  );

  return response.data;
};
