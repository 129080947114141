export const LangKeys = {
  // DEFAULT
  ADD_NEW_RESOURCE: "addNewResource",
  ADD_NEW_RESOURCES: "addNewResources",
  ADD_ORGANIZATION_RESOURCE: "addOrganizationResource",
  WELCOME_MESSAGE: "welcomeMessage",
  SELECT_YOUR_ORGANIZATION: "selectYourOrganization",
  RESOURCE_DELETE_CONFIRMATION_TEXT: "resourceDeleteConfirmationText",
  DELETE_ORGANIZATION: "deleteOrganization",
  CREATE_NEW_ORGANIZATION: "createNewOrganization",
  NO_ORGANIZATION_HAS_BEEN_ADDED_YET: "noOrganizationHasBeenAddedYet",
  ORGANIZATION_RESOURCES: "organizationResources",
  DELETE_RESOURCE: "deleteResource",
  EDIT_RESOURCE: "editResource",
  NO_RESOURCES_HAS_BEEN_ADDED_YET: "noResourcesHasBeenAddedYet",
  COPY_INPUT: "copyInput",
  INVITE_USER: "inviteUser",
  NO_RESOURCES: "noResources",
  ORGANIZATIONS: "organizations",
  DELETE_USER: "deleteUser",
  DELETE_USER_CONFIRMATION: "deleteUserConfirmation",

  // UI
  SETTINGS: "settings",
  LOGOUT: "logout",
  BACK: "back",
  CONFIRM: "confirm",
  CANCEL: "cancel",

  // TABLE & FORMS
  ARE_YOU_SURE_YOU_WANT_DELETE_THIS_VARIABLE:
    "areYouSureYouWantDeleteThisVariable",
  ADD_DERIVED_PLANT_VARIABLE: "addDerivedPlantVariable",
  APPLY: "apply",
  DELETE: "delete",
  TYPE: "type",
  STATUS: "status",
  CREATED_AT: "createdAt",
  MODIFIED_AT: "modifiedAt",
  MODIFIED_BY: "modifiedBy",
  RESOURCE_NAME: "resourceName",
  RESOURCE_ID: "resourceId",
  RESOURCE_ID_SMALL: "resourceIdSmall",
  RESOURCE_TYPE: "resourceType",
  ORGANIZATION_NAME: "organizationName",
  ORGANIZATION_ID: "organizationId",
  ORGANIZATION_DETAILS: "organizationDetails",
  ORGANIZATION_DELETE_CONFIRMATION_TEXT: "organizationDeleteConfirmationText",
  RESOURCE_INFORMATION: "resourceInformation",
  PRIMARY_KEY: "primaryKey",
  CONNECTION_STRING: "connectionString",
  CUSTOMER_ID: "customerId",
  PERMISSION_API: "permissionAPI",
  USER_DETAILS: "userDetails",
  USER_EMAIL: "userEmail",
  PROJECT: "project",
  ROLES: "roles",
  PROJECT_ID: "projectId",
  SELECT_PROJECT: "selectProject",
  SELECT_ROLES: "selectRoles",
  DISCARD: "discard",
  SET_0: "set0",
  PREVIOUS_VALUE: "previousValue",
  CLAMP: "clamp",
  CONTINUOUS: "continuous",
  ON_CHANGE: "onChange",
  DISCRETE: "discrete",
  RECEIVING_TYPE: "receivingType",
  CALCULATION_TIME_WINDOW: "calculationTimeWindow",
  IF_OUT_OF_RANGE: "ifOutOfRange",
  MAX_VALUE: "maxValue",
  MIN_VALUE: "minValue",
  CONDITION: "condition",
  VARIABLES: "variables",
  OPERATORS: "operators",
  FUNCTIONS: "functions",
  DURATION: "duration",
  RESOURCES: "resources",
  VARIABLE: "variable",
  PLANT_VARIABLE: "plantVariable",
  DERIVED_PLANT_VARIABLE: "derivedPlantVariable",
  ADD_NEW_VARIABLE: "addNewVariable",
  EDIT_VARIABLE: "editVariable",
  EDIT_VARIABLES: "editVariables",
  EDIT_DEFAULT_CONFIGURATION: "editDefaultConfiguration",
  ADD_PROJECT: "addProject",
  REMOVE_PROJECT: "removeProject",
  USERS: "users",
  FULL_NAME_SEARCH: "fullNameSearch",
  SELECT_AGE: "selectAge",
  ADD_VALUE: "addValue",
  DELETE_VARIABLE: "deleteVariable",
  VARIABLE_NAME: "variableName",
  VALUE: "value",
  SEARCH_VARIABLE: "searchVariable",
  AVERAGING: "averaging",
  SELECT_AVERAGING_TYPE: "selectAveragingType",
  TIME_BASED_AVERAGING: "Time based averaging",
  SAMPLE_BASED_AVERAGING: "Sample based averaging",
  DISABLED_VARIABLE: "disabledVariable",
  SELECT_DURATION_TYPE: "selectDurationType",
  FULL_NAME: "fullName",
  EMAIL_ADDRESS: "emailAddress",
  COPIED: "copied",
  VALIDATION_EQUAL_OR_MORE_THAN: "validationQualOrMoreThan",
  VALIDATION_EQUAL_OR_LESS_THAN: "validationQualOrLessThan",
  VALUE_SEARCH: "valueSearch",

  // MATH
  ADDITIONAL: "additional",
  SUBTRATION: "subtration",
  MULTIPLICATION: "multiplication",
  DIVISION: "division",
  MODULO: "modulo",
  EXPONENTIATION: "exponentiation",

  // STATUSES
  ERROR: "error",
  ACTIVE: "active",
  COMPLETE: "complete",

  // HINTS
  HINT_CUSTOMER_ID_FROM_SALESFORCE: "hint_customerIdFromSalesforce",
  HINT_PERMISSION_API: "hint_permissionAPI",

  // NOTIFICATIONS
  NOTIFICATION_SOMETHING_WENT_WRONG: "notification_somethingWentWrong",

  NOTIFICATION_ORGANIZATION_ADDED_SUCCESS:
    "notification_organizationAddedSuccess",
  NOTIFICATION_ORGANIZATION_ADDED_FAILED:
    "notification_organizationAddedFailed",

  NOTIFICATION_ORGANIZATION_UPDATED_SUCCESS:
    "notification_organizationUpdatedSuccess",
  NOTIFICATION_ORGANIZATION_UPDATED_FAILED:
    "notification_organizationUpdatedFailed",

  NOTIFICATION_ORGANIZATION_DELETED_SUCCESS:
    "notification_organizationDeletedSuccess",
  NOTIFICATION_ORGANIZATION_DELETED_FAILED:
    "notification_organizationDeletedFailed",

  NOTIFICATION_ORGANIZATION_NOT_FOUND: "notification_organizationNotFound",

  NOTIFICATION_RESOURCE_ADDED_SUCCESS: "notification_resourceAddedSuccess",
  NOTIFICATION_RESOURCE_ADDED_FAILED: "notification_resourceAddedFailed",

  NOTIFICATION_RESOURCE_UPDATED_SUCCESS: "notification_resourceUpdatedSuccess",
  NOTIFICATION_RESOURCE_UPDATED_FAILED: "notification_resourceUpdatedFailed",

  NOTIFICATION_RESOURCE_DELETED_SUCCESS: "notification_resourceDeletedSuccess",
  NOTIFICATION_RESOURCE_DELETED_FAILED: "notification_resourceDeletedFailed",

  NOTIFICATION_RESOURCE_NOT_FOUND: "notification_resourceNotFound",

  NOTIFICATION_USER_INVITED_SUCCESS: "notification_userInvitedSuccess",
  NOTIFICATION_USER_INVITED_FAILED: "notification_userInvitedFailed",

  NOTIFICATION_USER_UPDATED_SUCCESS: "notification_userUpdatedSuccess",
  NOTIFICATION_USER_UPDATED_FAILED: "notification_userUpdatedFailed",

  NOTIFICATION_VARIABLE_EDIT_SUCCESS: "notification_variableEditSuccess",
  NOTIFICATION_VARIABLE_EDIT_FAILED: "notification_variableEditFailed",

  NOTIFICATION_VARIABLE_ADDED_SUCCESS: "notification_variableAddedSuccess",
  NOTIFICATION_VARIABLE_ADDED_FAILED: "notification_variableAddedFailed",

  NOTIFICATION_VARIABLE_DELETED_SUCCESS: "notification_variableDeletedSuccess",
  NOTIFICATION_VARIABLE_DELETED_FAILED: "notification_variableDeletedFailed",

  NOTIFICATION_CREATE_NEW_ORGANIZATION_IN_PROGRESS:
    "notification_createNewOrganizationInProgress",
  NOTIFICATION_CREATE_NEW_RESOURCE_IN_PROGRESS:
    "notification_createNewResourceInProgress",

  NOTIFICATION_USER_DELETED_SUCCESS: "notification_userDeletedSuccess",
  NOTIFICATION_USER_DELETED_FAILED: "notification_userDeletedFailed",

  // FORM VALIDATION
  VALIDATION_REQUIRED: "validation_required",
  VALIDATION_WITHOUT_SPACES: "validation_withoutSpaces",
  VALIDATION_SMALL_SIGNS: "validation_withoutSpaces",

  // TABLE

  // TIME
  SECONDS: "seconds",
  MINUTES: "minutes",
  HOURS: "hours",
  DAY: "day",
};
