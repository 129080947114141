import { vestResolver } from "@hookform/resolvers/vest";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { LinkRequest } from "@/api/types/commonTypes";
import { Button, Input } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import MathOperationInput from "@/components/MathOperationInput/MathOperationInput";
import {
  replaceVariableKeyToValueInExpressionByExpressionInput,
  validateExpression,
} from "@/components/MathOperationInput/utils";
import { useFetchResourceConfiguration } from "@/queries/resourcesQuery/resourcesQuery";
import {
  useAddVariable,
  useGetVariables,
} from "@/queries/variablesQuery/variablesQuery";
import { IfOutOfRangeList, RECEIVING_TYPE } from "@/utils/consts";
import {
  inputIsNotEmpty,
  inputMustBeEqualOrLessThan,
  inputMustBeEqualOrMoreThan,
} from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

type AveragingProps = "time-based-averaging" | "simple-based-averaging" | "";

export type AddNewDerivedVariablePropsForm = {
  variableName: string;
  expression: string;
  minLimit: string;
  maxLimit: string;
  outOfRange: string;
  averaging: AveragingProps;
  duration: string;
  expressionInput: { [key: string]: string };
  durationType: string;
};

const AddNewDerivedVariable = ({ organizationId, resourceId }: LinkRequest) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialog();
  const { data: resourceVariablesData } = useGetVariables({
    organizationId,
    resourceId,
  });

  const { data: resourceConfig } = useFetchResourceConfiguration({
    organizationId,
    resourceId,
  });

  const { mutate, isPending } = useAddVariable();

  const variableList = resourceVariablesData
    ?.filter(
      (variable) =>
        variable.configuration && variable.configuration.expression.length === 0
    )
    .map((variable) => ({
      value: variable.variableName,
      name: variable.variableName,
    }));

  const schema = create((data: AddNewDerivedVariablePropsForm) => {
    inputIsNotEmpty("variableName", data.variableName);
    inputIsNotEmpty("minLimit", data.minLimit);
    inputIsNotEmpty("maxLimit", data.maxLimit);
    inputIsNotEmpty("outOfRange", data.outOfRange);
    inputIsNotEmpty("expression", data.expression);
    inputMustBeEqualOrMoreThan(
      "minLimit",
      Number(data.minLimit),
      Number(resourceConfig?.defaultVariableConfiguration.minLimit)
    );
    inputMustBeEqualOrLessThan(
      "maxLimit",
      Number(data.maxLimit),
      Number(resourceConfig?.defaultVariableConfiguration.maxLimit)
    );
  });

  const methods = useForm<AddNewDerivedVariablePropsForm>({
    mode: "all",
    defaultValues: {
      variableName: "",
      expression: "",
      minLimit: "",
      maxLimit: "",
      outOfRange: "",
      averaging: "",
      expressionInput: {},
      duration: "",
      durationType: "",
    },
    resolver: vestResolver(schema),
  });

  const {
    formState: { errors },
  } = methods;

  const submit = (data: AddNewDerivedVariablePropsForm) => {
    if (!validateExpression(data.expression)) {
      alert("Invalid expression");
      return;
    }

    // In the design, there is no option to select the receiving type.
    const receivingTypeStatus = RECEIVING_TYPE.continuous;

    const prepareData = {
      variableName: data.variableName,
      configuration: {
        maxAge: 0,
        minLimit: Number(data.minLimit),
        maxLimit: Number(data.maxLimit),
        invalidMeasurementHandling: Number(data.outOfRange),
        expression: replaceVariableKeyToValueInExpressionByExpressionInput(
          data.expression,
          data.expressionInput
        ),
        expressionInput: data.expressionInput,
        type: receivingTypeStatus,
      },
    };

    mutate({
      organizationId,
      resourceId,
      body: prepareData,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <div className={styles.formStyles}>
          <h3>{t(LangKeys.ADD_DERIVED_PLANT_VARIABLE)}</h3>

          <Input
            errorText={errors.variableName?.message}
            label={t(LangKeys.VARIABLE_NAME)}
            {...methods.register("variableName")}
          />

          <MathOperationInput variables={variableList} />

          <div className={styles.twoInputsWrapper}>
            <div className={styles.inputWrapper}>
              <Input
                errorText={errors.minLimit?.message}
                label={t(LangKeys.MIN_VALUE)}
                placeholder={t(LangKeys.VALUE)}
                {...methods.register("minLimit")}
              />
            </div>

            <div className={styles.inputWrapper}>
              <Input
                errorText={errors.maxLimit?.message}
                label={t(LangKeys.MAX_VALUE)}
                placeholder={t(LangKeys.VALUE)}
                {...methods.register("maxLimit")}
              />
            </div>
          </div>

          <MOSelect
            label={t(LangKeys.IF_OUT_OF_RANGE)}
            onMoAfterHide={(e: any) => e.stopPropagation()}
            errorText={errors.outOfRange?.message}
            placeholder={t(LangKeys.CONDITION)}
            {...methods.register("outOfRange")}
          >
            {IfOutOfRangeList.map((item) => (
              <MOOption key={item.value} value={`${item.value}`}>
                {item.name}
              </MOOption>
            ))}
          </MOSelect>

          <div className={styles.dialogFooter}>
            <Button type="button" variant="subtle" onClick={hideDialog}>
              {t(LangKeys.CANCEL)}
            </Button>

            <Button type="submit" variant="primary" loading={isPending}>
              {t(LangKeys.APPLY)}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddNewDerivedVariable;
