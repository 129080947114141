export const errorResponseHandler = (error: any) => {
  if (
    typeof error?.response?.data === "object" &&
    "detail" in error.response.data
  ) {
    return error.response.data.detail;
  } else {
    return error?.response?.data;
  }
};
