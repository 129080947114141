export const LOCAL_STORAGE = {
  LANGUAGE: "language",
  ACCESS_TOKEN: "accessToken",
};

export const localStorageUtils = {
  // token
  getAccessToken: (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
  },
  setAccessToken: (token: string) => {
    return localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token);
  },

  // language
  getLanguageSelection: (): string | null =>
    localStorage.getItem(LOCAL_STORAGE.LANGUAGE),
  setLanguageSelection: (language: string) => {
    return localStorage.setItem(LOCAL_STORAGE.LANGUAGE, language);
  },
};
