import MOButton from "@metsooutotec/modes-web-components/dist/react/button";

type ButtonProps = React.ComponentProps<typeof MOButton> & {
  children?: React.ReactNode;
};

const Button = ({ children, ...props }: ButtonProps) => {
  return <MOButton {...props}>{children}</MOButton>;
};

export default Button;
