import { t } from "i18next";

import { IF_OUT_OF_RANGE } from "./consts";
import { LangKeys } from "./i18n/languageKeys";

export const getRangeName = (type: number) => {
  switch (type) {
    case IF_OUT_OF_RANGE.discard:
      return t(LangKeys.DISCARD);
    case IF_OUT_OF_RANGE.zero:
      return t(LangKeys.SET_0);
    case IF_OUT_OF_RANGE.previous:
      return t(LangKeys.PREVIOUS_VALUE);
    case IF_OUT_OF_RANGE.clamp:
      return t(LangKeys.CLAMP);
    default:
      return "";
  }
};
