import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { Button } from "@/components";

import { ReactComponent as Logo } from "../../assets/metso_logo.svg";
import styles from "./style.module.scss";

const LoginView = (): JSX.Element => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  const handleLogin = async () => {
    if (isAuthenticated) {
      return;
    }
    if (inProgress.match(InteractionStatus.None)) {
      await instance.loginRedirect();
    }
  };

  return (
    <div className={styles.loginPageWrapper}>
      <div className={styles.loginContainer}>
        <div className={styles.appTitleContainer}>
          <div className={styles.logoContainer}>
            <Logo aria-labelledby="Metso logo" className={styles.logo} />
          </div>
          <h2 className={styles.secondaryLogo}>Geminex™</h2>
        </div>
        <div className={styles.loginActionContainer}>
          <Button
            disabled={!inProgress.match(InteractionStatus.None)}
            onClick={handleLogin}
            className={styles.loginButton}
          >
            Log in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
