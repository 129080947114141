import { GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { DataGrid } from "@/components";
import { useGetVariables } from "@/queries/variablesQuery/variablesQuery";

import { useDerivedPlantVariablesContext } from "../../utils/DerivedPlantVariableProvider";
import useDerivedPlantVariableColumns from "./useDerivedPlantVariableColumns";

const DerivedPlantVariableTable = () => {
  const { organizationId, resourceId } = useParams();
  const { setDerivedPlantVariablesToEdit } = useDerivedPlantVariablesContext();

  const { data: resourceVariablesData, isPending } = useGetVariables({
    organizationId,
    resourceId,
  });

  const data = useMemo(() => {
    return resourceVariablesData
      ? resourceVariablesData
          .filter(
            (variable) =>
              variable?.configuration !== null &&
              variable?.configuration?.expression !== undefined &&
              variable.configuration.expression.trim() !== ""
          )
          .map((variable) => ({
            ...variable.configuration,
            variableId: variable.variableName,
            variableName: variable.variableName,
            calculationTimeWindow: variable.configuration
              ? variable.configuration.maxAge
              : 0,
            outOfRange: variable.configuration
              ? variable.configuration.invalidMeasurementHandling
              : 0,
            receivingType: variable.configuration
              ? variable.configuration.type
              : 0,
          }))
      : [];
  }, [resourceVariablesData]);

  const columnsDef = useDerivedPlantVariableColumns({
    organizationId,
    resourceId,
  });

  const handleUpdateSelectedRows = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows() ?? [];

    setDerivedPlantVariablesToEdit(selectedRows.map((row) => row.variableId));
  };

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: columnsDef,
      rowSelection: "multiple",
      onSelectionChanged: handleUpdateSelectedRows,
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: false,
      },
    }),
    [columnsDef]
  );

  return (
    <DataGrid rowData={data} gridOptions={gridOptions} loading={isPending} />
  );
};

export default DerivedPlantVariableTable;
