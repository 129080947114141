import { organizationApiClient } from "../apiClient";
import {
  AddOrganizationRequest,
  CheckOrganizationStatusRequest,
  DeleteOrganizationRequest,
  Organization,
  UpdateOrganizationRequest,
} from "./types";

export const checkOrganizationStatus = async ({
  id,
}: CheckOrganizationStatusRequest) => {
  const response = await organizationApiClient.get(
    `/organizations/${id}/status`
  );

  return response;
};

export const fetchOrganizations = async (): Promise<Organization[]> => {
  const response = await organizationApiClient.get("/organizations");

  return response.data;
};

export const addOrganization = async ({
  body,
}: AddOrganizationRequest): Promise<Organization> => {
  const response = await organizationApiClient.post(`/organizations`, body);

  return response.data;
};

export const editOrganization = async ({
  id,
  data,
}: UpdateOrganizationRequest): Promise<Organization> => {
  const response = await organizationApiClient.put(
    `/organizations/${id}`,
    data
  );

  return response.data;
};

export const deleteOrganization = async ({
  id,
}: DeleteOrganizationRequest): Promise<void> => {
  await organizationApiClient.delete(`/organizations/${id}`);
};
