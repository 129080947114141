import { vestResolver } from "@hookform/resolvers/vest";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { LinkRequest } from "@/api/types/commonTypes";
import { Button, Input } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import MathOperationInput from "@/components/MathOperationInput/MathOperationInput";
import {
  replaceVariableKeyToValueInExpressionByExpressionInput,
  validateExpression,
} from "@/components/MathOperationInput/utils";
import { useFetchResourceConfiguration } from "@/queries/resourcesQuery/resourcesQuery";
import {
  useEditVariablesConfiguration,
  useGetVariables,
} from "@/queries/variablesQuery/variablesQuery";
import { IfOutOfRangeList, RECEIVING_TYPE } from "@/utils/consts";
import {
  inputIsNotEmpty,
  inputMustBeEqualOrLessThan,
  inputMustBeEqualOrMoreThan,
} from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

type EditDerivedVariableDialogProps = {
  derivedPlantVariablesToEdit: string[];
} & LinkRequest;

export type EditDerivedVariableForm = {
  variableNames: string[];
  expression: string;
  minLimit: number;
  maxLimit: number;
  outOfRange: string;
  averaging: string;
  duration: number;
  expressionInput: {};
  durationType: string;
  numberOfSamples: string;
};

const defaultValues = {
  expression: "",
  minLimit: 0,
  maxLimit: 0,
  outOfRange: "",
  averaging: "",
  expressionInput: {},
  duration: 0,
  numberOfSamples: "",
  invalidMeasurementHandling: 0,
};

const EditDerivedVariableDialog = ({
  derivedPlantVariablesToEdit,
  organizationId,
  resourceId,
}: EditDerivedVariableDialogProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { data: resourceVariablesData } = useGetVariables({
    organizationId,
    resourceId,
  });
  const { mutate, isPending } = useEditVariablesConfiguration();

  const { data: resourceConfig } = useFetchResourceConfiguration({
    organizationId,
    resourceId,
  });

  const singleSelectedVariableInfo =
    resourceConfig?.variableConfigurations &&
    derivedPlantVariablesToEdit.length > 1
      ? defaultValues
      : resourceConfig?.variableConfigurations?.[
          derivedPlantVariablesToEdit[0]
        ];

  const variableList = resourceVariablesData
    ?.filter(
      (variable) =>
        variable.configuration && variable.configuration.expression === ""
    )
    .map((variable) => ({
      value: variable.variableName,
      name: variable.variableName,
    }));

  const schema = create((data: EditDerivedVariableForm) => {
    inputIsNotEmpty("minLimit", data.minLimit);
    inputIsNotEmpty("maxLimit", data.maxLimit);
    inputIsNotEmpty("outOfRange", data.outOfRange);
    inputMustBeEqualOrMoreThan(
      "minLimit",
      Number(data.minLimit),
      Number(resourceConfig?.defaultVariableConfiguration.minLimit)
    );
    inputMustBeEqualOrLessThan(
      "maxLimit",
      Number(data.maxLimit),
      Number(resourceConfig?.defaultVariableConfiguration.maxLimit)
    );
  });

  const methods = useForm<EditDerivedVariableForm>({
    defaultValues: {
      ...singleSelectedVariableInfo,
      outOfRange: `${singleSelectedVariableInfo?.invalidMeasurementHandling}`,
      variableNames: derivedPlantVariablesToEdit,
    },
    resolver: vestResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = (data: EditDerivedVariableForm) => {
    if (!validateExpression(data.expression)) {
      return;
    }

    // In the design, there is no option to select the receiving type.
    const receivingTypeStatus = RECEIVING_TYPE.continuous;

    const prepareData = {
      variableNames: data.variableNames,
      configuration: {
        maxAge: 0,
        minLimit: Number(data.minLimit),
        maxLimit: Number(data.maxLimit),
        invalidMeasurementHandling: Number(data.outOfRange),
        expression: replaceVariableKeyToValueInExpressionByExpressionInput(
          data.expression,
          data.expressionInput
        ),
        expressionInput: data.expressionInput,
        type: receivingTypeStatus,
      },
    };

    mutate({
      organizationId,
      resourceId,
      body: prepareData,
    });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formStyles}>
            <h3>{t(LangKeys.EDIT_VARIABLE)}</h3>

            <MathOperationInput
              variables={variableList}
              expressionData={singleSelectedVariableInfo?.expression ?? ""}
              expressionInputData={
                singleSelectedVariableInfo?.expressionInput ?? {}
              }
            />

            <div className={styles.twoInputsWrapper}>
              <div className={styles.inputWrapper}>
                <Input
                  errorText={errors.minLimit?.message}
                  label={t(LangKeys.MIN_VALUE)}
                  placeholder={t(LangKeys.VALUE)}
                  {...register("minLimit")}
                />
              </div>

              <div className={styles.inputWrapper}>
                <Input
                  errorText={errors.maxLimit?.message}
                  label={t(LangKeys.MAX_VALUE)}
                  placeholder={t(LangKeys.VALUE)}
                  {...register("maxLimit")}
                />
              </div>
            </div>

            <MOSelect
              label={t(LangKeys.IF_OUT_OF_RANGE)}
              placeholder={t(LangKeys.CONDITION)}
              errorText={errors.outOfRange?.message}
              onMoAfterHide={(e: any) => e.stopPropagation()}
              {...register("outOfRange")}
            >
              {IfOutOfRangeList.map((item) => (
                <MOOption key={item.value} value={`${item.value}`}>
                  {item.name}
                </MOOption>
              ))}
            </MOSelect>

            <div className={styles.dialogFooter}>
              <Button type="button" variant="subtle" onClick={hideDialog}>
                {t(LangKeys.CANCEL)}
              </Button>

              <Button type="submit" variant="primary" loading={isPending}>
                {t(LangKeys.APPLY)}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditDerivedVariableDialog;
