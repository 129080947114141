import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { LinkRequest } from "@/api/types/commonTypes";
import {
  addNewVariable,
  deleteVariable,
  editDerivedVariablesConfiguration,
  getAllVariables,
} from "@/api/variablesSlice/variableSlice";
import { useDialog } from "@/components/Dialog/DialogProvider";
import useNotificationStore from "@/store/useNotificationStore.store";
import i18n from "@/utils/i18n/i18n";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { QUERIES_KEYS } from "../queriesKeys";

type ErrorType = Error & {
  response: {
    data: string;
  };
};

export const useGetVariables = ({
  organizationId,
  resourceId,
}: LinkRequest) => {
  return useQuery({
    queryFn: () => getAllVariables({ organizationId, resourceId }),
    queryKey: [QUERIES_KEYS.variables, organizationId, resourceId],
  });
};

export const useAddVariable = () => {
  const { hideDialog } = useDialog();
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: addNewVariable,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_ADDED_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useEditVariablesConfiguration = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: editDerivedVariablesConfiguration,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_EDIT_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useDeleteVariable = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: deleteVariable,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_DELETED_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};
