import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  Button,
  EditResourceConfiguration,
  EditVariableDialog,
  Icon,
  PlantVariableTable,
} from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useFetchResourceConfiguration } from "@/queries/resourcesQuery/resourcesQuery";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { usePlantVariablesContext } from "@/utils/PlantVariablesProvider";
import { isObjectEmpty } from "@/utils/utils";

import style from "./styles.module.scss";

const PlantVariableTab = () => {
  const { organizationId, resourceId } = useParams();
  const { showDialog } = useDialog();
  const { plantVariablesToEdit } = usePlantVariablesContext();
  const { t } = useTranslation();

  const { data: resourceConfig } = useFetchResourceConfiguration({
    organizationId,
    resourceId,
  });

  const isConfigEmpty = isObjectEmpty(
    resourceConfig?.defaultVariableConfiguration
  );

  const openEditResourceConfigurationDialog = () => {
    setTimeout(() => {
      showDialog(
        <EditResourceConfiguration
          organizationId={organizationId}
          resourceId={resourceId}
        />,
        "medium"
      );
    }, 0);
  };

  useEffect(() => {
    if (isConfigEmpty) openEditResourceConfigurationDialog();
  }, [resourceConfig]);

  return (
    <div>
      <div className={style.buttonsGroup}>
        <Button
          name="edit"
          variant="secondary"
          onClick={() =>
            showDialog(
              <EditResourceConfiguration
                organizationId={organizationId}
                resourceId={resourceId}
              />,
              "medium"
            )
          }
        >
          <Icon slot="prefix" name="settings"></Icon>
          {t(LangKeys.EDIT_DEFAULT_CONFIGURATION)}
        </Button>

        <Button
          name="edit"
          variant="secondary"
          disabled={!(plantVariablesToEdit.length > 0)}
          onClick={() =>
            showDialog(
              <EditVariableDialog
                organizationId={organizationId}
                resourceId={resourceId}
                variablesIds={plantVariablesToEdit}
              />,
              "medium"
            )
          }
        >
          <Icon slot="prefix" name="edit"></Icon>
          {t(LangKeys.EDIT_VARIABLES)}
        </Button>
      </div>

      <PlantVariableTable />
    </div>
  );
};

export default PlantVariableTab;
